import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactBreakpoints from 'react-breakpoints';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app';
import { antdConfig } from './customization/antd.config';
import { mediaBreakpoints } from './constants';

import './i18n.js';

import './customization/custom.scss';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ConfigProvider theme={antdConfig}>
      <BrowserRouter>
        <ReactBreakpoints breakpoints={mediaBreakpoints}>
          <App />
        </ReactBreakpoints>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>,
);
