import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { localeKeys, tabNames } from '../../constants';
import { TabPane } from './tab-pane/tab-pane';
import { TabLink } from './tab-pane/tab-link/tab-link';

import './landing-tabs.scss';

type TabNames = {
  'top-management': string,
  hr: string,
  employees: string,
};

const tabContent = {
  'top-management': [
    localeKeys.traforyPurposeManagement1,
    localeKeys.traforyPurposeManagement2,
    localeKeys.traforyPurposeManagement3,
    localeKeys.traforyPurposeManagement4,
  ],
  hr: [
    localeKeys.traforyPurposeHr1,
    localeKeys.traforyPurposeHr2,
    localeKeys.traforyPurposeHr3,
    localeKeys.traforyPurposeHr4,
    localeKeys.traforyPurposeHr5,
  ],
  employees: [
    localeKeys.traforyPurposeEmployee1,
    localeKeys.traforyPurposeEmployee2,
    localeKeys.traforyPurposeEmployee3,
    localeKeys.traforyPurposeEmployee4,
  ],
};

const items = [
  {
    key: tabNames['top-management'],
    name: tabNames['top-management'],
    label: localeKeys.traforyPurposeManagementTitle,
  },
  {
    key: tabNames.hr,
    name: tabNames.hr,
    label: localeKeys.traforyPurposeHrTitle,
  },
  {
    key: tabNames.employees,
    name: tabNames.employees,
    label: localeKeys.traforyPurposeEmployeeTitle,
  },
];

type Props = {
  className?: string
};

export const LandingTabs = ({ className }:Props) => {
  const [currentTab, setCurrentTab] = useState(tabNames['top-management']);
  const { t } = useTranslation();
  const [isAnimatedTabs, setAnimatedTabs] = useState(true);

  const disableAnimation = () => {
    setAnimatedTabs(false);
  };

  const setAnimation = () => {
    setAnimatedTabs(true);
  };

  useEffect(() => {
    const interval = isAnimatedTabs ? setInterval(() => {
      const currentTabIndex = R.findIndex(R.propEq('name', currentTab), items);

      setCurrentTab(items[currentTabIndex + 1]?.name || tabNames['top-management']);
    }, 5000) : null;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentTab, isAnimatedTabs]);

  return (
    <div
      onMouseEnter={disableAnimation}
      onMouseLeave={setAnimation}
      className={classNames(className, 'landing-tabs d-flex flex-column')}
    >
      <div className="landing-tabs-navbar w-100 px-2 px-md-2_5">
        <div className="d-flex align-items-center landing-tabs-navbar-inner">
          {items.map(({ name, label }) => (
            <TabLink
              key={name}
              name={name}
              label={t(label)}
              onTabClick={setCurrentTab}
              isActive={name === currentTab}
              isAnimatedTabs={isAnimatedTabs}
            />
          ))}
        </div>
      </div>
      <div className="tab-panes w-100 flex-grow-1 d-flex overflow-x-hidden px-2 px-md-2_5 pt-lg-1">
        {items.map(({ name }) => (
          <TabPane
            key={name}
            tabName={name}
            tabItems={tabContent[name as keyof TabNames]}
            isActive={name === currentTab}
          />
        ))}
      </div>
    </div>
  );
};

LandingTabs.defaultProps = {
  className: null,
};
