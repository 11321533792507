import React from 'react';

type Props = {
  className?: string,
};

export const IconBook = ({ className }: Props) => (
  <svg className={className} width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36.5" cy="36.5" r="36.5" fill="#8243AF" fillOpacity="0.1" />
    <path d="M54.75 36.5V47.9063C54.75 52.2078 54.75 54.3586 53.4137 55.6949C52.0773 57.0312 49.9266 57.0312 45.625 57.0312H23.9531C20.8034 57.0312 18.25 54.4779 18.25 51.3281V51.3281C18.25 48.1784 20.8034 45.625 23.9531 45.625H45.625C49.9266 45.625 52.0773 45.625 53.4137 44.2887C54.75 42.9523 54.75 40.8016 54.75 36.5V25.0937C54.75 20.7922 54.75 18.6414 53.4137 17.3051C52.0773 15.9688 49.9266 15.9688 45.625 15.9688H27.375C23.0734 15.9688 20.9227 15.9688 19.5863 17.3051C18.25 18.6414 18.25 20.7922 18.25 25.0937V51.3281" stroke="#8243AF" strokeWidth="2.28125" />
    <path d="M27.375 27.375L45.625 27.375" stroke="#8243AF" strokeWidth="2.28125" strokeLinecap="round" />
    <path d="M27.375 34.2188L38.7812 34.2188" stroke="#8243AF" strokeWidth="2.28125" strokeLinecap="round" />
  </svg>
);

IconBook.defaultProps = {
  className: null,
};
