import React, { useCallback, useEffect, useState } from 'react';
import { Header } from 'antd/lib/layout/layout';
import { Button } from 'antd';
import { PauseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { localeKeys, ROUTES } from '../../constants';
import { LandingHeaderMenu } from './landing-header-menu/landing-header-menu';
import { LanguageSelect } from './language-select/language-select';
import { TraforySocials } from '../trafory-socials/trafory-socials';
import { StickyHeader } from './sticky-header';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';
import { IconLogo } from '../icons/icon-logo';

import './landing-header.scss';

export const LandingHeader = () => {
  const [isHeaderMenuOpened, setHeaderMenuOpened] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const isBlogPage = location.pathname.includes(ROUTES.BLOG);
  const isPromoPage = location.pathname.includes(ROUTES.PROMO);
  const isHomePage = location.pathname.includes(ROUTES.HOME);

  const handleMenuButtonClick = useCallback(() => {
    setHeaderMenuOpened(!isHeaderMenuOpened);
  }, [isHeaderMenuOpened]);

  const closeHeaderMenu = useCallback(() => {
    setHeaderMenuOpened(false);
  }, []);

  useEffect(() => {
    closeHeaderMenu();
  }, [closeHeaderMenu, location]);

  useEffect(() => {
    if (isHeaderMenuOpened) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isHeaderMenuOpened]);

  return (
    <>
      <Header className="landing-header bg-white text-black d-flex align-items-center
        justify-content-between w-100"
      >
        {isPromoPage || isHomePage ? (
          <>
            <IconLogo isSmall linkContainerClassName="d-sm-none" />
            {isHomePage && (
              <IconLogo linkContainerClassName="d-none d-sm-block fill-primary" width={215} height="auto" />
            )}
          </>
        ) : (
          <div className="d-flex">
            <AnchorLinkSmooth
              to={`${isBlogPage ? ROUTES.HOME : ''}#bitrix-form`}
              className="d-none d-sm-block me-3 me-sm-2"
            >
              <Button
                type="primary"
              >
                {t(localeKeys.getAccess)}
              </Button>
            </AnchorLinkSmooth>

            <IconLogo isSmall linkContainerClassName="d-sm-none" />

            {/* TODO: uncomment when needed
            <Button
              className="me-auto"
              type="primary"
              href="https://youtu.be/NxpxZi-IXKs"
              target="_blank"
              ghost
            >
              {t(localeKeys.watchVideo)}
            </Button>
          */}
          </div>
        )}

        <TraforySocials className="d-none d-sm-flex ms-auto" />

        <div className="d-flex ms-sm-2_5 ms-md-4">
          <div className="d-none d-sm-flex align-items-center">
            <LanguageSelect isDesktop />
          </div>

          <div className="d-flex align-items-center d-sm-none">
            <LanguageSelect />
          </div>

          <Button
            onClick={handleMenuButtonClick}
            className="landing-header-menu-button p-0 d-flex align-items-center justify-content-center"
            icon={<PauseOutlined style={{ fontSize: 30 }} rotate={90} />}
            ghost
          />
          <LandingHeaderMenu
            isPromoPage={isPromoPage}
            closeHeaderMenu={closeHeaderMenu}
            isHeaderMenuOpened={isHeaderMenuOpened}
            isBlogPage={isBlogPage}
          />
        </div>
      </Header>
      <StickyHeader
        isPromoPage={isPromoPage}
        isHeaderMenuOpened={isHeaderMenuOpened}
        setHeaderMenuOpened={setHeaderMenuOpened}
      />
    </>
  );
};
