import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { IconCheck } from '../../icons/icon-check';
import { localeKeys } from '../../../constants';

type Props = {
  tabItems: string[],
  tabName: string,
  isActive: boolean,
};

export const TabPane = ({ tabItems, tabName, isActive }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('tab-pane d-flex flex-column flex-md-row justify-content-between pt-sm-1', {
      active: isActive,
    })}
    >
      <div className="d-flex flex-column align-items-start text-md-main2 pe-md-2">
        {tabItems.map((description) => (
          <div key={description} className="trafory-purpose-tabs-info mt-2 mt-md-3 d-flex align-items-start w-100">
            <IconCheck />
            <div className="d-inline-block ms-1 ms-md-2 mt-0_25">{t(description)}</div>
          </div>
        ))}
        <Link
          className="mt-2_75 mt-md-6_5 align-self-center align-self-md-start"
          to={tabName}
        >
          <Button
            type="primary"
            size="large"
          >
            {t(localeKeys.more)}
          </Button>
        </Link>
      </div>
      <div
        className="purpose-tab-image mt-2_5 mt-sm-3"
        style={{ backgroundImage: `url("images/trafory-purpose/${tabName}.webp")` }}
      />
    </div>
  );
};
