import React, { useCallback, useEffect, useState } from 'react';
import { Header } from 'antd/lib/layout/layout';
import classNames from 'classnames';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PauseOutlined } from '@ant-design/icons';
import { IconLogo } from '../icons/icon-logo';
import { ROUTES, localeKeys } from '../../constants';
import { LanguageSelect } from './language-select/language-select';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';

type Props = {
  setHeaderMenuOpened: (e: boolean)=> void,
  isHeaderMenuOpened: boolean,
  isPromoPage: boolean,
};

export const StickyHeader = ({ setHeaderMenuOpened, isHeaderMenuOpened, isPromoPage }: Props) => {
  const [isSticky, setSticky] = useState(false);
  const { t } = useTranslation();

  const handleMenuButtonClick = useCallback(() => {
    setHeaderMenuOpened(!isHeaderMenuOpened);
  }, [isHeaderMenuOpened, setHeaderMenuOpened]);

  const onScroll = () => {
    if (document.documentElement.scrollTop > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Header
      className={classNames(
        'landing-header sticky-header bg-primary text-white w-100 position-fixed px-sm-3 px-lg-14',
        'd-flex align-items-center justify-content-between',
        {
          'sticky-header-show': isSticky,
        },
      )}
    >
      <IconLogo />

      <div className="d-none d-md-block ms-auto">
        <LanguageSelect isSticky={isSticky} isDesktop />
      </div>

      {!isPromoPage && (
        <AnchorLinkSmooth
          to={`${ROUTES.HOME}#bitrix-form`}
          className="ms-auto ms-md-0 me-1 me-sm-3 me-md-5"
        >
          <Button
            className="sticky-header-access-button text-primary bg-white"
            type="primary"
          >
            {t(localeKeys.getAccess)}
          </Button>
        </AnchorLinkSmooth>
      )}

      {/* menu button mobile */}
      <Button
        onClick={handleMenuButtonClick}
        className="landing-header-menu-button d-md-none p-0 d-flex align-items-center justify-content-center"
        icon={(
          <PauseOutlined
            style={{ fontSize: 30, color: 'white' }}
            rotate={90}
          />
        )}
        ghost
      />

      {/* menu button desktop */}
      <Button
        onClick={handleMenuButtonClick}
        className="landing-header-menu-button p-0 d-none d-md-flex align-items-center justify-content-center"
        icon={(
          <PauseOutlined
            style={{ fontSize: 30, color: '#3396AA' }}
            rotate={90}
          />
        )}
      />
    </Header>
  );
};
