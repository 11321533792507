import React from 'react';

type Props = {
  className?: string,
};

export const IconResult = ({ className }: Props) => (
  <svg className={className} width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="24.5" fill="#8243AF" fillOpacity="0.1" />
    <path d="M29.8594 13.7812C32.01 13.7812 33.0852 13.7812 33.8577 14.2974C34.1921 14.5208 34.4792 14.8079 34.7026 15.1423C35.2188 15.9148 35.2188 16.99 35.2188 19.1406V33.6875C35.2188 36.5749 35.2188 38.0185 34.3218 38.9155C33.4248 39.8125 31.9811 39.8125 29.0938 39.8125H19.9062C17.0189 39.8125 15.5752 39.8125 14.6782 38.9155C13.7812 38.0185 13.7812 36.5749 13.7812 33.6875V19.1406C13.7812 16.99 13.7812 15.9148 14.2974 15.1423C14.5208 14.8079 14.8079 14.5208 15.1423 14.2974C15.9148 13.7812 16.99 13.7812 19.1406 13.7812" stroke="#8243AF" strokeWidth="1.53125" />
    <path d="M19.9062 13.7812C19.9062 12.0899 21.2774 10.7188 22.9688 10.7188H26.0312C27.7226 10.7188 29.0938 12.0899 29.0938 13.7812C29.0938 15.4726 27.7226 16.8438 26.0312 16.8438H22.9687C21.2774 16.8438 19.9062 15.4726 19.9062 13.7812Z" stroke="#8243AF" strokeWidth="1.53125" />
    <path d="M19.9062 27.8409L22.6679 29.9122C23.3087 30.3928 24.2116 30.2983 24.7391 29.6955L30.625 22.9688" stroke="#8243AF" strokeWidth="1.53125" strokeLinecap="round" />
  </svg>
);

IconResult.defaultProps = {
  className: null,
};
