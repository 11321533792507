import React from 'react';

type Props = {
  className?: string,
};

export const IconConversation = ({ className }: Props) => (
  <svg className={className} width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23.5" cy="23.5" r="23.5" fill="#4094DA" fillOpacity="0.1" />
    <circle cx="14.6868" cy="29.5374" r="1.95833" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <circle cx="14.6875" cy="30.8438" r="5.875" stroke="#4094DA" strokeWidth="0.88125" />
    <path d="M18.6048 35.2224C18.3738 34.5283 17.8647 33.9151 17.1566 33.4777C16.4484 33.0403 15.5808 32.8032 14.6882 32.8032C13.7955 32.8032 12.9279 33.0403 12.2197 33.4777C11.5116 33.9151 11.0025 34.5283 10.7715 35.2224" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <circle cx="32.3118" cy="29.5374" r="1.95833" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <circle cx="32.3125" cy="30.8438" r="5.875" stroke="#4094DA" strokeWidth="0.88125" />
    <path d="M36.2298 35.2224C35.9988 34.5283 35.4897 33.9151 34.7816 33.4777C34.0734 33.0403 33.2058 32.8032 32.3132 32.8032C31.4205 32.8032 30.5529 33.0403 29.8447 33.4777C29.1366 33.9151 28.6275 34.5283 28.3965 35.2224" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <circle cx="23.4993" cy="13.3812" r="1.95833" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <circle cx="23.5" cy="14.6875" r="5.875" stroke="#4094DA" strokeWidth="0.88125" />
    <path d="M27.4173 19.0661C27.1863 18.3721 26.6772 17.7588 25.9691 17.3214C25.2609 16.8841 24.3933 16.647 23.5007 16.647C22.608 16.647 21.7404 16.8841 21.0322 17.3214C20.3241 17.7588 19.815 18.3721 19.584 19.0661" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <path d="M23.5 20.5625V21.9705C23.5 22.6635 23.5 23.01 23.5421 23.3122C23.7769 24.9998 24.9637 26.4007 26.5897 26.9098C26.8809 27.001 27.2227 27.0579 27.9062 27.1719V27.1719" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
    <path d="M23.5 20.5625V21.9705C23.5 22.6635 23.5 23.01 23.4579 23.3122C23.2231 24.9998 22.0363 26.4007 20.4103 26.9098C20.1191 27.001 19.7773 27.0579 19.0938 27.1719V27.1719" stroke="#4094DA" strokeWidth="0.88125" strokeLinecap="round" />
  </svg>
);

IconConversation.defaultProps = {
  className: null,
};
