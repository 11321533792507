import React from 'react';

type Props = {
  className?: string,
};

export const IconBriefcase = ({ className }: Props) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.9987 2.08301C6.94015 2.08301 6.08203 2.94113 6.08203 3.99967C6.08203 4.41389 5.74624 4.74967 5.33203 4.74967C4.91782 4.74967 4.58203 4.41389 4.58203 3.99967C4.58203 2.1127 6.11173 0.583008 7.9987 0.583008C9.88567 0.583008 11.4154 2.1127 11.4154 3.99967C11.4154 4.41389 11.0796 4.74967 10.6654 4.74967C10.2512 4.74967 9.91536 4.41389 9.91536 3.99967C9.91536 2.94113 9.05724 2.08301 7.9987 2.08301ZM5.33203 6.25C5.74624 6.25 6.08203 6.58579 6.08203 7V9C6.08203 9.41421 5.74624 9.75 5.33203 9.75C4.91782 9.75 4.58203 9.41421 4.58203 9V7C4.58203 6.58579 4.91782 6.25 5.33203 6.25ZM11.4141 7C11.4141 6.58579 11.0783 6.25 10.6641 6.25C10.2498 6.25 9.91406 6.58579 9.91406 7V9C9.91406 9.41421 10.2498 9.75 10.6641 9.75C11.0783 9.75 11.4141 9.41421 11.4141 9V7Z" fill="#3396AA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33203 3.25C1.81325 3.25 0.582031 4.48122 0.582031 6C0.582031 6.69847 0.842433 7.33613 1.27145 7.82118C1.25743 7.87848 1.25 7.93837 1.25 8V11.4667L1.25 11.4971C1.24999 12.0313 1.24998 12.4771 1.27974 12.8413C1.31078 13.2212 1.37789 13.5779 1.54973 13.9151C1.81338 14.4326 2.23408 14.8533 2.75153 15.1169C3.08879 15.2888 3.44545 15.3559 3.82533 15.3869C4.18956 15.4167 4.6354 15.4167 5.16955 15.4167H5.2H10.8H10.8305C11.3646 15.4167 11.8104 15.4167 12.1747 15.3869C12.5546 15.3559 12.9112 15.2888 13.2485 15.1169C13.7659 14.8533 14.1866 14.4326 14.4503 13.9151C14.6221 13.5779 14.6892 13.2212 14.7203 12.8413C14.75 12.4771 14.75 12.0313 14.75 11.4972V11.4971V11.4667V8C14.75 7.93755 14.7424 7.87688 14.728 7.81887C15.1558 7.33411 15.4154 6.69737 15.4154 6C15.4154 4.48122 14.1841 3.25 12.6654 3.25H3.33203ZM13.25 8.68773C13.0616 8.72852 12.866 8.75 12.6654 8.75H3.33203C3.13233 8.75 2.93761 8.72871 2.75 8.68829V11.4667C2.75 12.0391 2.75058 12.4233 2.77476 12.7192C2.79822 13.0063 2.8401 13.1436 2.88624 13.2342C3.00608 13.4694 3.19731 13.6606 3.43251 13.7804C3.52307 13.8266 3.66035 13.8685 3.94748 13.8919C4.24336 13.9161 4.62757 13.9167 5.2 13.9167H10.8C11.3724 13.9167 11.7566 13.9161 12.0525 13.8919C12.3396 13.8685 12.4769 13.8266 12.5675 13.7804C12.8027 13.6606 12.9939 13.4694 13.1138 13.2342C13.1599 13.1436 13.2018 13.0063 13.2252 12.7192C13.2494 12.4233 13.25 12.0391 13.25 11.4667V8.68773ZM12.6654 4.75C13.3557 4.75 13.9154 5.30964 13.9154 6C13.9154 6.69036 13.3557 7.25 12.6654 7.25H3.33203C2.64167 7.25 2.08203 6.69036 2.08203 6C2.08203 5.30964 2.64168 4.75 3.33203 4.75H12.6654Z" fill="#3396AA" />
  </svg>
);

IconBriefcase.defaultProps = {
  className: null,
};
