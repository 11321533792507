import React, { JSXElementConstructor } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BenefitTabPane } from './tab-pane/benefit-tab-pane';
import { makeNumbersArray } from '../../../helpers/numbers';

import './benefit-tabs.scss';

type Props = {
  className?: string
  currentTab: number,
  setCurrentTab: (i: any)=> void,
  items: {
    Icon: JSXElementConstructor<any>,
    itemsCount: number
  }[],
};

export const BenefitTabs = ({
  className, currentTab, setCurrentTab, items,
}:Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(className, 'your-benefits-tabs d-none d-sm-flex justify-content-between')}
    >
      <div className="landing-tabs-navbar">
        {items.map(({ Icon }, index) => (
          <div
            role="button"
            tabIndex={index}
            onKeyDown={() => {
              setCurrentTab(index);
            }}
            onClick={() => {
              setCurrentTab(index);
            }}
            className={classNames('d-flex your-benefits-tab-label px-2 py-2_25 rounded-2 text-medium lg-h5 mb-0 font-weight-normal', {
              active: index === currentTab,
              'text-black': index !== currentTab,
            })}
          >
            <Icon className="your-benefits-tab-icon me-1" />
            <div>
              {t(`your-benefits.tab${index + 1}.name`)}
            </div>
          </div>
        ))}
      </div>
      <div className="tab-panes d-flex overflow-x-hidden">
        {items.map(({ itemsCount }, index) => (
          <BenefitTabPane
            tabName={index}
            isActive={currentTab === index}
            tabItems={makeNumbersArray(itemsCount)}
          />
        ))}
      </div>
    </div>
  );
};

BenefitTabs.defaultProps = {
  className: null,
};
