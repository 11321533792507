import React from 'react';

type Props = {
  className?: string,
};

export const IconBranch = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#3396AA" fillOpacity="0.1" />
    <rect x="22" y="19" width="4" height="4" rx="2" transform="rotate(90 22 19)" stroke="#3396AA" />
    <rect x="10" y="12" width="4" height="4" rx="2" transform="rotate(-90 10 12)" stroke="#3396AA" />
    <path d="M12 12V17C12 18.8856 12 19.8284 12.5858 20.4142C13.1716 21 14.1144 21 16 21H18" stroke="#3396AA" />
  </svg>
);

IconBranch.defaultProps = {
  className: null,
};
