export const isDOMAvailable = (): boolean => !!(window?.document?.createElement);

export const checkPixelRatio = (): string => {
  if (isDOMAvailable()) {
    const pixelRatio = window.devicePixelRatio;

    if (pixelRatio >= 2 && pixelRatio < 3) {
      return '@2x';
    }

    if (pixelRatio >= 3) {
      return '@3x';
    }

    return '';
  }

  return '';
};
