import Button from './component-styles/button/button.js';

export const antdConfig = {
  token: {
    colorPrimary: '#3396AA',
    colorBgLayout: '#FFF',
  },
  components: {
    Button,
    Select: {
      fontSize: 16,
    },
    Link: {
      fontSize: 16,
    },

  },
};
