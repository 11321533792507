import React from 'react';

type Props = {
  className?: string,
};

export const IconLocation = ({ className }: Props) => (
  <svg className={className} width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23.5" cy="23.5" r="23.5" fill="#4094DA" fillOpacity="0.1" />
    <path d="M34.3118 30.8437C35.4075 31.5136 35.9844 32.2734 35.9844 33.0469C35.9844 33.8203 35.4075 34.5802 34.3118 35.25C33.2161 35.9198 31.6401 36.4761 29.7422 36.8628C27.8443 37.2495 25.6915 37.4531 23.5 37.4531C21.3085 37.4531 19.1557 37.2495 17.2578 36.8628C15.3599 36.4761 13.7839 35.9198 12.6882 35.25C11.5925 34.5802 11.0156 33.8203 11.0156 33.0469C11.0156 32.2734 11.5925 31.5136 12.6882 30.8438" stroke="#4094DA" strokeWidth="1.46875" strokeLinecap="round" />
    <path d="M34.5156 20.5625C34.5156 27.9327 26.4547 32.9039 24.1241 34.1872C23.7304 34.404 23.2696 34.404 22.8759 34.1872C20.5453 32.9039 12.4844 27.9327 12.4844 20.5625C12.4844 13.9531 17.8218 9.54688 23.5 9.54688C29.375 9.54688 34.5156 13.9531 34.5156 20.5625Z" stroke="#4094DA" strokeWidth="1.46875" />
    <circle cx="23.5" cy="20.5625" r="5.14062" stroke="#4094DA" strokeWidth="1.46875" />
  </svg>
);

IconLocation.defaultProps = {
  className: null,
};
