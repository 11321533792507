import React from 'react';
import { useTranslation } from 'react-i18next';

import { localeKeys } from '../../constants';

import './users-geography.scss';

export const UsersGeography = () => {
  const { t } = useTranslation();

  return (
    <div className="users-geography w-100 position-relative mx-auto">
      <div className="h5 md-h1 users-geography-title position-absolute">
        {t(localeKeys.usersGeographyTitle)}
      </div>
    </div>
  );
};
