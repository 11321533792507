import { useEffect, useState } from 'react';
import { checkPixelRatio } from '../helpers/dom';

export const usePixelRatio = (): string => {
  const [pixelRatio, setPixelRatio] = useState('');

  useEffect(() => {
    setPixelRatio(checkPixelRatio());
  }, []);

  return pixelRatio;
};
