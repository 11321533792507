import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import {
  TechnicalCapabilitiesInfoBlock,
} from '../../components/technical-capabilities/technical-capabilities-info-block/technical-capabilities-info-block';
import {
  TechnicalCapabilitiesSupport,
} from '../../components/technical-capabilities/technical-capabilities-support/technical-capabilities-support';
import { MobileAppInfo } from '../../components/mobile-app-info/mobile-app-info';
import { TraforyRoadmap } from '../../components/trafory-roadmap/trafory-roadmap';
import { PAGE_NAMES } from '../../constants';

export const TechnicalCapabilities = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.TECHNICAL_CAPABILITIES} />
    <div className="main-container">
      <TechnicalCapabilitiesSupport />
      <TechnicalCapabilitiesInfoBlock />
      <TraforyRoadmap />
    </div>
    <MobileAppInfo />
  </div>
);
