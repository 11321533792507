import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { localeKeys, ROUTES } from '../../constants';
import { IconMaintenance } from '../icon-maintenance';

import './technical-capabilities.scss';

export const TechnicalCapabilities = () => {
  const { t } = useTranslation();

  return (
    <div className="technical-capabilities text-white w-100 bg-primary text-center">
      <div className="main-container overflow-hidden position-relative w-100">
        <IconMaintenance className="technical-capabilities-icon-left position-absolute" />
        <IconMaintenance className="technical-capabilities-icon-right position-absolute" />
        <div className="h5 md-h1 mt-2_5 mt-md-10">{t(localeKeys.technicalCapabilitiesTitle)}</div>
        <Link to={ROUTES.TECHNICAL_CAPABILITIES}>
          <Button
            type="primary"
            size="large"
            className="technical-capabilities-button bg-white bg-opacity-20 mt-1 mt-md-4 mb-3 mb-md-10"
          >
            {t(localeKeys.more)}
          </Button>
        </Link>
      </div>
    </div>
  );
};
