import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Img } from '../../../img/img';

type Props = {
  tabItems: number[],
  tabName: number,
  isActive: boolean,
};

export const BenefitTabPane = ({ tabItems, tabName, isActive }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('tab-pane d-flex flex-column flex-sm-column-reverse justify-content-between justify-content-sm-end pt-sm-1', {
      active: isActive,
    })}
    >
      <div className="d-flex flex-column align-items-center pt-2 pb-1 pb-md-5 px-1 mx-1 text-center
        border-bottom border-primary border-opacity-20 tab-pane-text"
      >
        {tabItems.map((i) => (
          <div key={i} className="mb-1 mb-sm-0_5 text-night-500 line-height-1_2 line-height-sm-1_4 text-lg-medium">
            {t(`your-benefits.tab${tabName + 1}.item${i + 1}`)}
          </div>
        ))}
      </div>
      <div className="w-100 px-md-10 px-lg-5 mb-md-3 mt-1 mt-md-3 mb-lg-0">
        <Img
          alt={`screen-${tabName + 1}`}
          className="your-benefits-tab-image w-100"
          src={`images/your-benefits/${tabName}.webp`}
        />
      </div>
    </div>
  );
};
