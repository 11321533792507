import React from 'react';

type Props = {
  className?: string,
};

export const IconCheck = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M22.5303 3.46967C22.8232 3.76256 22.8232 4.23744 22.5303 4.53033L12.5303 14.5303C12.2374 14.8232 11.7626 14.8232 11.4697 14.5303L8.46967 11.5303C8.17678 11.2374 8.17678 10.7626 8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697L12 12.9393L21.4697 3.46967C21.7626 3.17678 22.2374 3.17678 22.5303 3.46967Z" fill="#3396AA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 11.652 21.2308 11.3088 21.1935 10.9713C21.148 10.5596 21.4449 10.1889 21.8566 10.1434C22.2683 10.0979 22.6389 10.3948 22.6844 10.8065C22.7278 11.1986 22.75 11.5969 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C13.7149 1.25 15.338 1.6521 16.7782 2.36783C17.1492 2.55217 17.3004 3.00231 17.1161 3.37324C16.9317 3.74417 16.4816 3.89544 16.1107 3.7111C14.8732 3.09614 13.4781 2.75 12 2.75Z" fill="#3396AA" />
  </svg>
);

IconCheck.defaultProps = {
  className: null,
};
