import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';
import { IconPulse } from '../icons/icons-possibilities/icon-pulse';
import { IconBriefcase } from '../icons/icons-possibilities/icon-briefcase';
import { IconBookOpen } from '../icons/icons-possibilities/icon-book-open';
import { IconUsers } from '../icons/icons-possibilities/icon-users';

import './trafory-possibilities.scss';

const icons = [IconPulse, IconBriefcase, IconBookOpen, IconUsers];

export const TraforyPossibilities = () => {
  const { t } = useTranslation();

  return (
    <div className="trafory-possibilities px-2 px-md-3 px-lg-15">
      <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-5 text-center text-sm-start">
        {t(localeKeys.traforyPossibilitiesTitle)}
      </div>
      <div className="d-flex flex-column d-sm-grid trafory-possibilities-items">
        {makeNumbersArray(4).map((i) => {
          const Icon = icons[i];
          const currentItem = `trafory-possibilities.item${i + 1}`;

          return (
            <div
              key={i}
              className="trafory-possibilities-item
                pt-2 pt-lg-3 px-2_5 px-lg-5_5 pb-3 pb-sm-2_5 mb-1_25 mb-sm-0 mb-md-1 mb-lg-0
                bg-opacity-4 bg-primary border-start border-5 border-primary border-opacity-20 rounded-2"
            >
              <div className="d-flex align-items-center">
                <div className="rounded-circle bg-primary p-1 p-md-2_25 bg-opacity-10 me-1 me-md-2">
                  <Icon className="trafory-possibilities-item-icon" />
                </div>
                <div className="h5 md-h3 text-primary mb-0">
                  {t(`${currentItem}.title`)}
                </div>
              </div>
              <div className="px-md-3 px-lg-0 pt-md-0_5 pt-lg-1">
                {makeNumbersArray(4).map((item) => (
                  <div
                    key={item}
                    className="text-medium text-md-main2 mt-1 mt-md-1_5 mt-lg-2"
                  >
                    {t(`${currentItem}.description${item + 1}`)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
