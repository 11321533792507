import React from 'react';

type Props = {
  className?: string,
};

export const IconPhone = ({ className }: Props) => (
  <svg className={className} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="52" height="52" rx="26" fill="#3396AA" fillOpacity="0.1" />
    <path d="M18.7524 12.2524C18.1003 12.9045 17.8198 13.7255 17.6887 14.7006C17.5625 15.6398 17.5625 16.834 17.5625 18.3156L17.5625 18.375V34.625L17.5625 34.6844C17.5625 36.166 17.5625 37.3602 17.6887 38.2994C17.8198 39.2745 18.1003 40.0955 18.7524 40.7476C19.4045 41.3997 20.2255 41.6802 21.2006 41.8113C22.1398 41.9375 23.334 41.9375 24.8155 41.9375H24.875H28.125H28.1845C29.666 41.9375 30.8602 41.9375 31.7994 41.8113C32.7745 41.6802 33.5955 41.3997 34.2476 40.7476C34.8997 40.0955 35.1802 39.2745 35.3113 38.2994C35.4375 37.3602 35.4375 36.166 35.4375 34.6845V34.625V18.375V18.3155C35.4375 16.834 35.4375 15.6398 35.3113 14.7006C35.1802 13.7255 34.8997 12.9045 34.2476 12.2524C33.5955 11.6003 32.7745 11.3198 31.7994 11.1887C30.8602 11.0625 29.666 11.0625 28.1844 11.0625L28.125 11.0625H24.875L24.8156 11.0625C23.334 11.0625 22.1398 11.0625 21.2006 11.1887C20.2255 11.3198 19.4045 11.6003 18.7524 12.2524Z" stroke="#3396AA" strokeWidth="1.625" />
    <path d="M18.375 33H34.625" stroke="#3396AA" strokeWidth="1.625" />
  </svg>
);

IconPhone.defaultProps = {
  className: null,
};
