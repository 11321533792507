import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { localeKeys, ROUTES } from '../../constants';
import { IconDatepicker } from '../icons/icon-datepicker';
import { IconLifeBuoy } from '../icons/icons-your-benefits/icon-lifebuoy';
import { IconFlag } from '../icons/icon-flag';
import { makeNumbersArray } from '../../helpers/numbers';

import './trafory-experience.scss';

const icons = [IconDatepicker, IconLifeBuoy, IconFlag];

export const TraforyExperience = () => {
  const { t } = useTranslation();

  return (
    <div className="trafory-experience mt-10 pb-10 pt-4 px-2 px-md-3 px-lg-15 pt-lg-10">
      <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-5 text-center text-sm-start mx-2 mx-sm-0">
        {t(localeKeys.traforyExperienceTitle)}
      </div>
      <div className="bg-white trafory-experience-content py-2 rounded-2 pb-sm-0">
        <div className="trafory-experience-items d-flex d-lg-grid flex-column flex-wrap px-2 px-md-2_5">
          {makeNumbersArray(3).map((i) => {
            const Icon = icons[i];
            const isFirstItem = i === 0;

            return (
              <div
                key={i}
                className={classNames('trafory-experience-item d-flex flex-column mt-lg-4', {
                  'mt-2_5': !isFirstItem,
                })}
              >
                <div className="d-flex">
                  <div>
                    <Icon className="trafory-experience-item-icon me-1" />
                  </div>
                  <div className="lg-h5 mb-0 font-weight-normal">
                    {t(`trafory-experience.item${i + 1}.description`)}
                  </div>
                </div>
                {isFirstItem && (
                  <Link
                    className="d-inline-block mt-1_25 mt-lg-2 align-self-center align-self-lg-start"
                    target="_blank"
                    to={`${ROUTES.BLOG}/events`}
                    rel="noreferrer"
                  >
                    <Button
                      className="rounded-4 text-medium px-2_25 trafory-experience-item-button"
                      type="primary"
                      size="large"
                    >
                      {t(localeKeys.aboutEvent)}
                    </Button>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
        <div className="w-100 trafory-experience-image mt-1 mb-1_25 mt-md-6_75 mt-lg-8" />
      </div>
    </div>
  );
};
