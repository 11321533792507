import React from 'react';

type Props = {
  className?: string,
};

export const IconExpandLeft = ({ className }: Props) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 6L9 12L15 18" stroke="#3396AA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconExpandLeft.defaultProps = {
  className: null,
};
