import React from 'react';

type Props = {
  className?: string,
};

export const IconHash = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#8243AF" fillOpacity="0.1" />
    <path d="M14.6841 13.437H18.5691L19.3251 9.447H20.6061L19.8501 13.437H21.8661V14.55H19.6191L18.8001 18.897H21.8661V20.031H18.5901L17.8131 24H16.5321L17.3091 20.031H13.4241L12.6471 24H11.3661L12.1431 20.031H10.1271V18.897H12.3531L13.1721 14.55H10.1271V13.437H13.4031L14.1591 9.447H15.4401L14.6841 13.437ZM13.6341 18.897H17.5191L18.3591 14.55H14.4741L13.6341 18.897Z" fill="#8243AF" />
  </svg>
);

IconHash.defaultProps = {
  className: null,
};
