import i18n from 'i18next';
import http from './http-service';
import { PostData, Tag, TagData } from '../types/blog';

export const BlogService = {
  getTags: async () => {
    const result = await http.get(`/api/tags?populate=cover&locale=${i18n.resolvedLanguage}`);

    return result.data?.data.map(({ attributes, id }: TagData) => ({
      ...attributes,
      id,
      cover: attributes.cover.data?.attributes.url || null,
    })).sort((current: Tag, prev: Tag) => current.value - prev.value);
  },

  getAllPosts: async () => {
    const result = await http.get(`/api/posts?populate=*&sort=datePublished:desc&pagination[pageSize]=3&locale=${i18n.resolvedLanguage}`);

    return result.data?.data.map(({ attributes, id }: PostData) => ({
      ...attributes,
      id,
      cover: attributes.cover.data?.attributes.url || null,
      tag: {
        title: attributes.tag.data?.attributes.title || null,
        value: attributes.tag.data?.attributes.value,
      },
    }));
  },

  getPostsByTag: async (tag: number) => {
    const result = await http.get(`/api/posts?populate=*&sort=datePublished:desc&filters[tag][value][$eq]=${tag}&locale=${i18n.resolvedLanguage}`);
    return result.data?.data.map(({ attributes, id }: PostData) => ({
      ...attributes,
      id,
      cover: attributes.cover.data?.attributes.url || null,
      tag: {
        title: attributes.tag.data?.attributes.title || null,
        value: attributes.tag.data?.attributes.value,
      },
    }));
  },

  getPostByUrl: async (url: string) => {
    const result = await http.get(`/api/posts?populate=*&filters[url][$eq]=${url}&locale=${i18n.resolvedLanguage}`);
    return result.data?.data.map(({ attributes, id }: PostData) => ({
      ...attributes,
      id,
      cover: attributes.cover.data?.attributes.url || null,
      tag: {
        title: attributes.tag.data?.attributes.title || null,
        value: attributes.tag.data?.attributes.value,
      },
    }))[0];
  },

  getCompetencyModelImage: async () => {
    const result = await http.get('/api/competency-model?populate=*');

    return result.data.data.attributes.image.data.attributes.url;
  },
};
