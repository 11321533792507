import React from 'react';

type Props = {
  className?: string,
};

export const IconUsers = ({ className }: Props) => (
  <svg
    className={className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M11 0.583008C10.5858 0.583008 10.25 0.918794 10.25 1.33301C10.25 1.74722 10.5858 2.08301 11 2.08301C12.0585 2.08301 12.9167 2.94113 12.9167 3.99967C12.9167 5.05822 12.0585 5.91634 11 5.91634C10.5858 5.91634 10.25 6.25213 10.25 6.66634C10.25 7.08055 10.5858 7.41634 11 7.41634C12.887 7.41634 14.4167 5.88665 14.4167 3.99967C14.4167 2.1127 12.887 0.583008 11 0.583008ZM12.332 8.58301C11.9178 8.58301 11.582 8.91879 11.582 9.33301C11.582 9.74722 11.9178 10.083 12.332 10.083H13.132C14.4851 10.083 15.582 11.1799 15.582 12.533C15.582 13.297 14.9627 13.9163 14.1987 13.9163H12.332C11.9178 13.9163 11.582 14.2521 11.582 14.6663C11.582 15.0806 11.9178 15.4163 12.332 15.4163H14.1987C15.7911 15.4163 17.082 14.1254 17.082 12.533C17.082 10.3515 15.3136 8.58301 13.132 8.58301H12.332Z" fill="#3396AA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.41797 3.99967C4.41797 2.94113 5.27609 2.08301 6.33464 2.08301C7.39318 2.08301 8.2513 2.94113 8.2513 3.99967C8.2513 5.05822 7.39318 5.91634 6.33464 5.91634C5.27609 5.91634 4.41797 5.05822 4.41797 3.99967ZM6.33464 0.583008C4.44766 0.583008 2.91797 2.1127 2.91797 3.99967C2.91797 5.88665 4.44766 7.41634 6.33464 7.41634C8.22161 7.41634 9.7513 5.88665 9.7513 3.99967C9.7513 2.1127 8.22161 0.583008 6.33464 0.583008ZM4.86797 8.58301C2.68644 8.58301 0.917969 10.3515 0.917969 12.533C0.917969 14.1254 2.20888 15.4163 3.8013 15.4163H8.86797C10.4604 15.4163 11.7513 14.1254 11.7513 12.533C11.7513 10.3515 9.98283 8.58301 7.8013 8.58301H4.86797ZM7.8013 10.083C9.1544 10.083 10.2513 11.1799 10.2513 12.533C10.2513 13.297 9.63196 13.9163 8.86797 13.9163H3.8013C3.03731 13.9163 2.41797 13.297 2.41797 12.533C2.41797 11.1799 3.51487 10.083 4.86797 10.083H7.8013Z" fill="#3396AA" />
  </svg>
);

IconUsers.defaultProps = {
  className: null,
};
