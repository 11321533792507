import React, { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useBlogStore } from '../../../stores/blog-store';
import { BlogTags, localeKeys, ROUTES } from '../../../constants';
import { IconLogo } from '../../icons/icon-logo';
import { AppConstants } from '../../../app-constants';
import { Posts } from './posts/posts';
import { Tag } from '../../../types/blog';

import './blog-content.scss';

export const BlogContent = observer(() => {
  const store = useBlogStore();
  const { t, i18n: { language } } = useTranslation();
  const { pathname } = useLocation();
  const currentTag = Object.values(BlogTags).find((key: any) => pathname.includes(key)) || null;

  const tagInfo = store.tags.filter(({ value }: Tag) => BlogTags[value] === currentTag)[0] || null;

  useEffect(() => {
    store.getTags();

    return () => {
      store.tags = [];
    };
  }, [language]);

  if (currentTag && tagInfo) {
    return <Posts tagInfo={tagInfo} />;
  }

  return (
    <div className="blog-content d-flex flex-column align-items-center align-items-sm-start
      px-2 px-sm-3 px-lg-15 pt-6_25 pt-sm-4"
    >
      <div className="d-flex flex-column flex-sm-row mb-1 mb-sm-2 mb-md-3">
        <IconLogo />
        <div className="h5 text-center text-sm-start md-h1 text-primary ms-sm-1 align-self-sm-end mb-sm-1">
          {t(localeKeys.blog)}
        </div>
      </div>
      {store.isLoading ? <Spin /> : (
        <div className="blog-content-tags d-grid w-100 text-white">
          {store.tags?.map(({
            value, id, cover, title,
          }) => (
            <Link
              to={`${ROUTES.BLOG}/${BlogTags[value]}`}
              style={{ backgroundImage: `url("${AppConstants.server}${cover}")` }}
              className={`blog-content-tags-item text-decoration-none position-relative bg-primary p-2 px-md-5 py-md-4 ${BlogTags[value]}`}
              key={id}
            >
              <Button
                type="primary"
                className="position-relative z-1 text-md-main2 p-1_25 d-flex align-items-center"
                size="small"
              >
                {title}
              </Button>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
});
