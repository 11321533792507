import React, { useEffect } from 'react';
import { marked } from 'marked';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useBlogStore } from '../../../../../stores/blog-store';
import { AppConstants } from '../../../../../app-constants';
import { BlogTags, FORM_TYPES, ROUTES } from '../../../../../constants';
import { IconExpandLeft } from '../../../../icons/icon-expand-left';

import './selected-post.scss';
import { BitrixForm } from '../../../../bitrix-form/bitrix-form';

type Props = {
  url: string,
};

export const SelectedPost = observer(({ url }: Props) => {
  const store = useBlogStore();
  const { i18n: { language } } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    store.getPostByUrl(url);

    return () => {
      store.post = null;
    };
  }, [pathname, language]);

  return (
    <div className="selected-post p-2 px-md-3 pt-md-5 px-lg-15">
      <div className="d-flex align-items-start mb-2 mb-md-3">
        <Link
          to={`${ROUTES.BLOG}/${BlogTags[store.post?.tag.value]}`}
          className="mt-md-2"
        >
          <IconExpandLeft />
        </Link>
        <div className="h5 md-h1 mb-0 ms-1">
          {store.post?.title}
        </div>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div
        className="d-flex flex-column"
        dangerouslySetInnerHTML={{
          __html: marked(store.post?.body || '', {
            gfm: true,
            breaks: true,
          }).replaceAll('/uploads', `${AppConstants.server}/uploads`),
        }}
      />
      <BitrixForm
        className="py-7_5 py-7_5 px-2 py-md-10 px-md-auto"
        formType={FORM_TYPES.DEMO}
      />
    </div>
  );
});
