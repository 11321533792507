import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { localeKeys } from '../../constants';
import { IconElements } from '../icons/icons-your-benefits/icon-elements';
import { IconCup } from '../icons/icons-your-benefits/icon-cup';
import { IconUsers } from '../icons/icons-possibilities/icon-users';
import { IconPulse } from '../icons/icons-possibilities/icon-pulse';
import { BenefitTabs } from './benefits-tabs/benefit-tabs';
import { makeNumbersArray } from '../../helpers/numbers';
import { BenefitTabPane } from './benefits-tabs/tab-pane/benefit-tab-pane';
import { IconBox } from '../icons/icons-your-benefits/icon-box';
import { IconPieChart } from '../icons/icons-your-benefits/icon-pie-chart';

import './your-benefits.scss';

const tabs = [
  {
    Icon: IconElements,
    itemsCount: 3,
  },
  {
    Icon: IconCup,
    itemsCount: 1,
  },
  {
    Icon: IconPieChart,
    itemsCount: 2,
  },
  {
    Icon: IconUsers,
    itemsCount: 3,
  },
  {
    Icon: IconPulse,
    itemsCount: 3,
  },
  {
    Icon: IconBox,
    itemsCount: 3,
  },
];

export const YourBenefits = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab]: any = useState(undefined);

  const handleCollapse = useCallback((openedTabs: any) => {
    setCurrentTab(Number(openedTabs[openedTabs.length - 1]) || undefined);
  }, [setCurrentTab]);

  const collapseItems = tabs.map(({ itemsCount, Icon }, index) => ({
    key: index,
    label: (
      <div className="your-benefits-tab-label text-medium d-flex px-2 py-1">
        <Icon className="your-benefits-tab-icon" />
        <div className="mx-1 line-height-1_2">{t(`your-benefits.tab${index + 1}.name`)}</div>
      </div>
    ),
    children: <BenefitTabPane
      tabItems={makeNumbersArray(itemsCount)}
      tabName={index}
      isActive={index === currentTab}
    />,
  }));

  return (
    <div className="your-benefits mt-8_75 pt-4 pt-md-3 pb-5 px-sm-3 py-lg-10 px-lg-15">
      <div className="h5 md-h1 text-center text-sm-start mb-2 mb-md-3 mb-lg-5">
        {t(localeKeys.yourBenefitsTitle)}
      </div>
      <BenefitTabs
        setCurrentTab={setCurrentTab}
        currentTab={currentTab || 0}
        items={tabs}
      />
      <Collapse
        className="d-sm-none"
        items={collapseItems}
        defaultActiveKey={0}
        onChange={handleCollapse}
        expandIconPosition="end"
        activeKey={currentTab}
      />
    </div>
  );
};
