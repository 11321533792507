import React from 'react';

type Props = {
  className?: string,
};

export const IconLifeBuoy = ({ className }: Props) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="lifebuoy">
      <path id="Duo" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M5.46002 4.39838C5.16712 4.10549 4.69225 4.10549 4.39936 4.39838C4.10646 4.69127 4.10646 5.16615 4.39936 5.45904L8.15353 9.21322C7.58562 9.99594 7.25073 10.9587 7.25073 11.9998C7.25073 13.0408 7.58562 14.0036 8.15355 14.7863L4.39936 18.5405C4.10646 18.8334 4.10646 19.3083 4.39936 19.6012C4.69225 19.8941 5.16712 19.8941 5.46002 19.6012L9.21422 15.847C9.99694 16.4149 10.9597 16.7498 12.0007 16.7498C13.0417 16.7498 14.0045 16.4149 14.7873 15.847L18.5415 19.6012C18.8344 19.8941 19.3093 19.8941 19.6022 19.6012C19.895 19.3083 19.895 18.8334 19.6022 18.5405L15.8479 14.7863C16.4158 14.0036 16.7507 13.0408 16.7507 11.9998C16.7507 10.9588 16.4159 9.99597 15.848 9.21324L19.6022 5.45904C19.895 5.16615 19.895 4.69127 19.6022 4.39838C19.3093 4.10549 18.8344 4.10549 18.5415 4.39838L14.7873 8.15258C14.0046 7.58465 13.0418 7.24976 12.0007 7.24976C10.9597 7.24976 9.99692 7.58464 9.21419 8.15256L5.46002 4.39838ZM8.75073 11.9998C8.75073 10.2048 10.2058 8.74976 12.0007 8.74976C13.7957 8.74976 15.2507 10.2048 15.2507 11.9998C15.2507 13.7947 13.7957 15.2498 12.0007 15.2498C10.2058 15.2498 8.75073 13.7947 8.75073 11.9998Z" fill="#3396AA" />
      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="#3396AA" />
    </g>
  </svg>
);

IconLifeBuoy.defaultProps = {
  className: null,
};
