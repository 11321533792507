export const AppConstants = {
  /* dev */
  // server: 'https://cms_test.trafory.com:24443',
  // token: 'Bearer bc215fe20043f95f346b3dc10af97def82c148b49e6d9c14c812007cddfdd7838275b2f60479191fa22485063089807af78010b02a0f110e4aec379dbecaf780c079f9ddf08e3638458d93cd7bd905281745ab36feaf257b96e5d3e549087107bb143a725e39f8a44478ea6cd1d54031b6e9c14a5476bc7ffcafa05ccf4963b5',

  /* prod */
  server: 'https://cms.trafory.com',
  token: 'Bearer 044361219e67fa14c51cad4817aab2bdad497b8880c2f83c027dafca2e30dcfc19681eb2f588df29d36046d38713cb8c4833cca631090231fa38e19cc341c0ba71634074b90461cf7dd0414105739c7577b73c0d2627388fa5bb790fcd3bdf76953177debccef58bfb44b399d04d6d43461e999f3137249e4b0af41f1fd3870f',

  bitrixUrl: 'https://cdn-ru.bitrix24.by/b17502168',
};
