import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';

import './trafory-roadmap.scss';

export const TraforyRoadmap = () => {
  const { t } = useTranslation();

  return (
    <div className="trafory-roadmap px-2 px-md-3 position-relative pb-sm-15">
      <div className="h5 mb-2 mb-md-3 mb-lg-6 md-h1 text-center text-sm-start ms-lg-15">
        {t(localeKeys.traforyRoadmapTitle)}
      </div>
      <div className="position-relative z-1 d-flex flex-column flex-lg-row flex-lg-wrap justify-content-center trafory-roadmap-items">
        {makeNumbersArray(5).map((i) => {
          const item = `trafory-roadmap.item${i + 1}`;

          return (
            <div className="trafory-roadmap-item mb-7_5 mb-sm-2_5 text-lg-center">
              <div className="h5 md-h4 font-weight-md-bold text-primary mb-1">
                {i + 1}
                .
                {' '}
                {t(`${item}.title`)}
              </div>
              <div className="trafory-roadmap-item-description text-md-medium">
                {t(`${item}.description`)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="trafory-roadmap-image">
        <img
          alt="roadmap-small"
          className="d-sm-none"
          src="images/trafory-roadmap/roadmap-small.png"
        />
        <img
          alt="roadmap-tablet"
          className="d-none d-sm-block d-lg-none"
          src="images/trafory-roadmap/roadmap-tablet.png"
        />
        <div className="d-none d-lg-block roadmap-image-large w-100 position-absolute" />
      </div>
    </div>
  );
};
