import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { PartnersInvite } from '../../components/partners-invite/partners-invite';
import { PAGE_NAMES } from '../../constants';
import { MainScreen } from '../../components/main-screen/main-screen';
import { TraforyPurpose } from '../../components/trafory-purpose/trafory-purpose';
import {
  TechnicalCapabilities,
} from '../../components/technical-capabilities/technical-capabilities';
import { TraforyInstruments } from '../../components/trafory-instruments/trafory-instruments';
import { TraforyAdvantages } from '../../components/trafory-advantages/trafory-advantages';
import { UsersGeography } from '../../components/users-geography/users-geography';
import { TraforyPartners } from '../../components/trafory-partners/trafory-partners';
import { BlogInfo } from '../../components/blog/blog-info/blog-info';
import { MobileAppInfo } from '../../components/mobile-app-info/mobile-app-info';
import { Reviews } from '../../components/reviews/reviews';

export const Home = () => (
  <div className="w-100 home">
    <LandingHelmet page="home" />
    <div className="main-container">
      <MainScreen page={PAGE_NAMES.HOME} />
    </div>
    <TraforyInstruments />
    <TraforyPurpose />
    <TraforyPartners />
    <TraforyAdvantages />
    <MobileAppInfo />
    <div className="main-container">
      <Reviews />
    </div>
    <UsersGeography />
    <PartnersInvite />
    <BlogInfo />
    <TechnicalCapabilities />
  </div>
);
