import React from 'react';
import { PAGE_NAMES } from './constants';
import {
  Home, Hr, Blog, TopManagement, LearningManagementSystem,
  HumanResourceManagement, EvaluationTools, Employees, TechnicalCapabilities, CompetencyModel, TraforyPolicy,
} from './pages';
import { Promo } from './pages/promo/promo';

export const routes = [
  {
    page: PAGE_NAMES.HOME,
    path: '/',
    element: Home(),
  },
  {
    page: PAGE_NAMES.TOP_MANAGEMENT,
    path: '/top-management',
    element: TopManagement(),
  },
  {
    page: PAGE_NAMES.HR,
    path: '/hr',
    element: Hr(),
  },
  {
    page: PAGE_NAMES.EMPLOYEES,
    path: '/employees',
    element: Employees(),
  },
  {
    page: PAGE_NAMES.TECHNICAL_CAPABILITIES,
    path: '/technical-capabilities',
    element: TechnicalCapabilities(),
  },
  {
    page: PAGE_NAMES.LEARNING_MANAGEMENT_SYSTEM,
    path: '/learning-management-system',
    element: LearningManagementSystem(),
  },
  {
    page: PAGE_NAMES.EVALUATION_TOOLS,
    path: '/evaluation-tools',
    element: EvaluationTools(),
  },
  {
    page: PAGE_NAMES.HUMAN_RESOURCE_MANAGEMENT,
    path: '/human-resource-management',
    element: HumanResourceManagement(),
  },
  {
    page: PAGE_NAMES.BLOG,
    path: '/blog',
    element: Blog(),
  },
  {
    page: PAGE_NAMES.BLOG,
    path: '/blog/:tag',
    element: Blog(),
  },
  {
    page: PAGE_NAMES.BLOG,
    path: '/blog/:tag/:post',
    element: Blog(),
  },
  {
    page: PAGE_NAMES.COMPETENCY_MODEL,
    path: '/bonus',
    element: CompetencyModel(),
  },

  {
    page: PAGE_NAMES.PROMO,
    path: '/promo',
    element: Promo(),
  },
  {
    page: PAGE_NAMES.TRAFORY_POLICY,
    path: '/trafory-policy',
    element: <TraforyPolicy />,
  },
];
