import React from 'react';
import { Button } from 'antd';
import { Media } from 'react-breakpoints';
import { Trans, useTranslation } from 'react-i18next';
import { Img } from '../img/img';

import { localeKeys, mediaBreakpoints } from '../../constants';

import './mobile-app-info.scss';

const mobileAppLinks = [
  {
    name: 'google-play',
    url: 'https://play.google.com/store/apps/details?id=by.dots.trafory_app',
  },
  {
    name: 'app-store',
    url: 'https://apps.apple.com/ru/app/trafory/id1541718498',
  },
  {
    name: 'app-gallery',
    url: 'https://appgallery.huawei.com/app/C107158101',
  },
];

export const MobileAppInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="mobile-app-info text-white px-2 px-sm-3 py-2_5 pt-md-5 pt-lg-10 px-md-5_5 pb-sm-5 pb-md-10 pb-lg-14">
      <div className="inner-container">
        <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-3_5 text-center text-sm-start font-weight-medium">
          {t(localeKeys.mobileAppTitle)}
        </div>
        <span className="text-medium text-md-main2">
          <Trans
            components={{ bold: <span className="font-weight-md-bold h5 md-h4 mb-0" /> }}
            values={{ time: '24/7' }}
          >
            {localeKeys.mobileAppDescription}
          </Trans>
        </span>
        <div className="mobile-app-info-links d-flex flex-column mt-sm-2_5 mt-md-6 mt-lg-5 flex-sm-row
        align-items-center justify-content-between mx-auto"
        >
          <Media>
            {({ currentBreakpoint, breakpoints }: any) => breakpoints[currentBreakpoint] < mediaBreakpoints.sm && (
              <Img
                className="mobile-app-info-image-mobile my-2"
                alt="mobile-phone"
                src="images/mobile-app-info/phone-mobile.webp"
              />
            )}
          </Media>
          {mobileAppLinks.map(({ name, url }) => (
            <div
              key={name}
              className="mobile-app-info-links-item d-flex flex-column align-items-center mb-1 mb-sm-0"
            >
              <Media>
                {({ currentBreakpoint, breakpoints }: any) => breakpoints[currentBreakpoint] >= mediaBreakpoints.sm && (
                  <Img
                    className="mobile-app-info-links-item-image mx-auto"
                    alt={name}
                    src={`images/mobile-app-info/${name}/phone.webp`}
                  />
                )}
              </Media>
              <Button
                type="link"
                className="mt-sm-2 mt-lg-7"
                target="_blank"
                href={url}
              >
                <img
                  className="mobile-app-info-links-item-link"
                  alt={`${name}-link`}
                  src={`images/mobile-app-info/${name}/link.png`}
                />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
