import React, { useCallback } from 'react';
import classNames from 'classnames';

type Props = {
  name: string,
  label: string,
  onTabClick: (e: string)=> void,
  isActive: boolean,
  isAnimatedTabs: boolean,
};

export const TabLink = ({
  onTabClick, name, label, isActive, isAnimatedTabs,
}:Props) => {
  const handleClick = useCallback(({ currentTarget }: any) => {
    const scrollContainer = document.querySelector('.landing-tabs-navbar');
    const [{ left, right }] = currentTarget.getClientRects();

    if (scrollContainer && (left < 0 || right > window.innerWidth)) {
      scrollContainer.scrollBy({ left: left - 50, behavior: 'smooth' });
    }

    onTabClick(name);
  }, [name, onTabClick]);

  return (
    <div
      aria-label={name}
      tabIndex={0}
      onKeyDown={handleClick}
      onClick={handleClick}
      role="button"
      style={{ whiteSpace: 'nowrap' }}
      className={classNames(
        'landing-tabs-navbar-item d-flex position-relative align-items-center justify-content-center me-1 me-md-5 md-h4',
        {
          active: isActive,
          'font-weight-normal font-weight-md-normal': !isActive,
          'animated-tab': isAnimatedTabs,
        },
      )}
      onKeyPress={handleClick}
    >
      {label}
    </div>
  );
};
