import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { pagesMeta } from './pages-meta';
import { Pages } from '../../types/pages';

type Props = {
  page: keyof Pages,
};

export const LandingHelmet = ({ page }: Props) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t(`seo.title.${pagesMeta[page].title}`)}</title>
      <meta
        key="description"
        name="description"
        content={t(`seo.description.${t(pagesMeta[page].description)}`)}
      />
    </Helmet>
  );
};
