import React, {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconLogo } from '../icons/icon-logo';
import { localeKeys, PAGE_NAMES } from '../../constants';
import { IconQuestion } from '../icons/icons-main-screen/icon-question';
import { IconPhone } from '../icons/icons-main-screen/icon-phone';
import { IconBranches } from '../icons/icons-main-screen/icon-branches';
import { IconSettings } from '../icons/icons-main-screen/icon-settings';
import { IconBranch } from '../icons/icons-main-screen/icon-branch';
import { IconHash } from '../icons/icons-main-screen/icon-hash';
import { IconHat } from '../icons/icons-main-screen/icon-hat';
import { IconBook } from '../icons/icons-main-screen/icon-book';
import { IconMap } from '../icons/icons-main-screen/icon-map';
import { IconPeoples } from '../icons/icons-main-screen/icon-peoples';
import { IconResult } from '../icons/icons-main-screen/icon-result';
import { IconStack } from '../icons/icons-main-screen/icon-stack';
import { IconConversation } from '../icons/icons-main-screen/icon-conversation';
import { IconLocation } from '../icons/icons-main-screen/icon-location';
import { IconMail } from '../icons/icons-main-screen/icon-mail';
import { IconContact } from '../icons/icons-main-screen/icon-contact';
import { TraforySocials } from '../trafory-socials/trafory-socials';
import { usePixelRatio } from '../../hooks/use-pixel-ratio';
import { ScreenFrame } from './screen-frame/screen-frame';
import { Pages } from '../../types/pages';

import './main-screen.scss';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';

const frames = [0, 1, 2];
const timeout = 2000;

const frameIcons = [
  {
    frame: frames[0],
    icons: [
      {
        id: 1,
        Icon: IconQuestion,
      },
      {
        id: 2,
        Icon: IconBranch,
      },
      {
        id: 3,
        Icon: IconPhone,
      },
      {
        id: 4,
        Icon: IconSettings,
      },
      {
        id: 5,
        Icon: IconBranches,
      },
      {
        id: 6,
        Icon: IconQuestion,
      },
    ],
  }, {
    frame: frames[1],
    icons: [
      {
        id: 1,
        Icon: IconHat,
      },
      {
        id: 2,
        Icon: IconHash,
      },
      {
        id: 3,
        Icon: IconResult,
      },
      {
        id: 4,
        Icon: IconPeoples,
      },
      {
        id: 5,
        Icon: IconMap,
      },
      {
        id: 6,
        Icon: IconBook,
      },
    ],
  }, {
    frame: frames[2],
    icons: [
      {
        id: 1,
        Icon: IconMail,
      },
      {
        id: 2,
        Icon: IconMap,
      },
      {
        id: 3,
        Icon: IconContact,
      },
      {
        id: 4,
        Icon: IconLocation,
      },
      {
        id: 5,
        Icon: IconStack,
      },
      {
        id: 6,
        Icon: IconConversation,
      },
    ],
  },
];

type Props = {
  page: keyof Pages,
};

export const MainScreen = ({ page }: Props) => {
  const { t } = useTranslation();

  const [currentItem, setCurrentItem] = useState(0);
  const pixelRatio = usePixelRatio();
  const isHomePage = page === PAGE_NAMES.HOME;
  const isEmployeePage = page === PAGE_NAMES.EMPLOYEES;
  const isHrManagementPage = page === PAGE_NAMES.HUMAN_RESOURCE_MANAGEMENT;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem(currentItem < frames.length - 1 ? currentItem + 1 : 0);
    }, timeout);

    return () => {
      clearInterval(interval);
    };
  }, [currentItem]);

  const mainScreenDescription = `${page}MainScreen.description`;

  return (
    <div
      className={
        classNames('main-screen position-relative pb-8 pb-sm-0 ', {
          'home-page mb-sm-2': isHomePage,
          'hr-management-page': isHrManagementPage,
          'employee-page': isEmployeePage,
          'mb-sm-6 mb-md-14': !isHomePage,
        })
      }
    >
      <div
        className={classNames('d-flex flex-column align-items-center text-center position-relative z-2 mx-1', {
          'mt-6_25 mt-sm-4': !isHomePage,
          'mt-5_5 mt-sm-5 ms-sm-3 ms-md-5_5 ms-lg-15 align-items-sm-start mt-lg-14': isHomePage,
        })}
      >
        {isHomePage ? (
          <div className="text-sm-start">
            <div className="h2 md-h1 text-lg-large2 position-relative font-weight-bold line-height-1_3 mb-0">
              {t(`${page}MainScreen.title`)}
            </div>
            <div className="text-medium2 text-sm-main2 text-lg-large line-height-1_3 md-h2 mb-0 font-weight-normal" style={{ marginTop: -7 }}>
              {t(mainScreenDescription)}
            </div>
          </div>
        ) : (
          <>
            <IconLogo
              className="main-screen-logo"
              linkContainerClassName="mb-1 mb-sm-1 mb-md-3 mt-md-2"
            />
            <div className="h5 md-h2 text-primary main-screen-title position-relative font-weight-normal">
              {t(`${page}MainScreen.title`)}
            </div>
            <div className="main-screen-description break-spaces text-medium md-h5 font-weight-normal line-height-1_4">
              {/* description mobile */}
              <span className="d-sm-none">
                {t(`${mainScreenDescription}.mobile`, t(mainScreenDescription))}
              </span>
              {/* description tablet */}
              <span className="d-none d-sm-inline d-md-none">
                {t(`${mainScreenDescription}.tablet`, t(mainScreenDescription))}
              </span>
              {/* description laptop */}
              <span className="d-none d-md-inline d-lg-none">
                {t(`${mainScreenDescription}.laptop`, t(mainScreenDescription))}
              </span>
              {/* description desktop */}
              <span className="d-none d-lg-inline">
                {t(`${mainScreenDescription}.desktop`, t(mainScreenDescription))}
              </span>
            </div>
          </>
        )}
        <AnchorLinkSmooth
          to="#bitrix-form"
          className={classNames('anchor-link', {
            'mt-2_75 d-sm-none': !isHomePage,
            'mt-2 mt-sm-3_5 mt-md-3 mt-lg-4': isHomePage,
          })}
        >
          <Button
            size="large"
            type="primary"
            className="px-2 px-sm-1_25 px-md-2_25 px-lg-3"
          >
            {t(localeKeys.getAccess)}
          </Button>
        </AnchorLinkSmooth>

      </div>
      <div className="main-screen-icons position-absolute w-100">
        {frameIcons.map(({ frame, icons }) => (
          <div
            key={frame}
            className={classNames(
              `main-screen-icons-frame position-absolute main-screen-icons-frame-${frame} w-100`,
              {
                'opacity-0': frame !== currentItem,
              },
            )}
          >
            {icons.map(({ id, Icon }) => (
              <Icon
                key={`frame${frame}-icon${id}`}
                className={`main-screen-icons-frame-${frame}-icon-${id} position-absolute`}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="main-screen-animated-photos position-absolute h-100 d-none d-md-block overflow-x-hidden">
        {[1, 2, 3, 4, 5].map((i) => (
          <img
            key={i}
            alt={`animated-${i}`}
            src={`images/main-screen/animated-photos/photo${i}/photo${pixelRatio}.png`}
            className={`main-screen-animated-photos-item-${i} position-absolute animated-photo`}
          />
        ))}
      </div>
      <div className={classNames('main-screen-frames d-flex justify-content-center align-items-center position-relative w-100', {
        'mt-6': !isHomePage,
        'mt-2': isHomePage,
      })}
      >
        {frames.map((i) => (
          <ScreenFrame key={i} i={i} page={page} currentItem={currentItem} />
        ))}
      </div>
      <TraforySocials
        className={classNames('mx-auto d-sm-none', {
          'mt-6_5 mt-sm-auto': !isHomePage,
        })}
      />
    </div>
  );
};
