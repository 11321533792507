import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';
import { IconPulse } from '../icons/icons-possibilities/icon-pulse';
import { IconDatepicker } from '../icons/icon-datepicker';
import { IconPieChart } from '../icons/icons-your-benefits/icon-pie-chart';

import './integration-results.scss';

const icons = [IconPulse, IconPulse, IconPieChart, IconDatepicker, IconPieChart];

export const IntegrationResults = () => {
  const { t } = useTranslation();

  return (
    <div className="integration-results mt-10 px-2 px-sm-3 px-lg-15">
      <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-5 text-center text-sm-start">
        {t(localeKeys.integrationResultsTitle)}
      </div>
      <div className="d-flex flex-column flex-sm-row flex-sm-wrap integration-results-items justify-content-center">
        {makeNumbersArray(5).map((i) => {
          const item = `integration-results.item${i + 1}`;
          const Icon = icons[i];

          return (
            <div
              key={i}
              className="integration-results-item bg-primary bg-opacity-5 pt-2_5 px-3 pb-4
                d-flex flex-column align-items-center text-center rounded-2"
            >
              <div className="rounded-circle p-2_25 bg-primary d-inline-block">
                <Icon className="integration-results-item-icon" />
              </div>
              <div className="h5 mb-0_5 text-primary mt-1 lg-h3">
                {t(`${item}.title`)}
              </div>
              <span className="text-md-medium lg-h4 mb-0 font-weight-normal mb-lg-11_5">
                {t(`${item}.description`)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
