import React from 'react';

type Props = {
  className?: string,
};

export const IconBranches = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="22" y="13" width="4" height="4" rx="2" transform="rotate(90 22 13)" stroke="#3396AA" />
    <rect x="22" y="21" width="4" height="4" rx="2" transform="rotate(90 22 21)" stroke="#3396AA" />
    <rect x="7" y="11" width="4" height="4" rx="2" transform="rotate(-90 7 11)" stroke="#3396AA" />
    <path d="M9 12V19C9 20.8856 9 21.8284 9.58579 22.4142C10.1716 23 11.1144 23 13 23H18" stroke="#3396AA" />
    <path d="M9 11V11C9 12.8856 9 13.8284 9.58579 14.4142C10.1716 15 11.1144 15 13 15H18" stroke="#3396AA" />
    <rect width="32" height="32" rx="16" fill="#3396AA" fillOpacity="0.1" />
  </svg>
);

IconBranches.defaultProps = {
  className: null,
};
