import { PagesMeta } from '../../types/pages';

export const pagesMeta: PagesMeta = {
  home: {
    title: 'home',
    description: 'home',
  },
  topManagement: {
    title: 'topManagement',
    description: 'topManagement',
  },
  hr: {
    title: 'hr',
    description: 'hr',
  },
  employees: {
    title: 'employees',
    description: 'employees',
  },
  technicalCapabilities: {
    title: 'technicalCapabilities',
    description: 'technicalCapabilities',
  },
  learningManagementSystem: {
    title: 'learningManagementSystem',
    description: 'learningManagementSystem',
  },
  evaluationTools: {
    title: 'evaluationTools',
    description: 'evaluationTools',
  },
  humanResourceManagement: {
    title: 'humanResourceManagement',
    description: 'humanResourceManagement',
  },
  blog: {
    title: 'blog',
    description: 'blog',
  },
  competencyModel: {
    title: 'competencyModel',
    description: 'competencyModel',
  },
  promo: {
    title: 'promo',
    description: 'promo',
  },
  'trafory-policy': {
    title: 'promo',
    description: 'promo',
  },
};
