import React from 'react';

type Props = {
  className?: string,
};

export const IconPeoples = ({ className }: Props) => (
  <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25" r="25" fill="#8243AF" fillOpacity="0.1" />
    <path d="M11.2732 23.1012C12.0118 22.035 13.0861 21.1334 14.4012 20.5007C15.7164 19.8679 17.2167 19.5313 18.75 19.5313C20.2833 19.5312 21.7836 19.8679 23.0988 20.5007C24.4139 21.1334 25.4882 22.035 26.2268 23.1012" stroke="#8243AF" strokeWidth="1.5625" strokeLinecap="round" />
    <path d="M25.3357 40.2887C26.0743 39.2225 27.1486 38.3209 28.4637 37.6882C29.7789 37.0554 31.2792 36.7188 32.8125 36.7188C34.3458 36.7187 35.8461 37.0554 37.1613 37.6882C38.4764 38.3209 39.5507 39.2225 40.2893 40.2887" stroke="#8243AF" strokeWidth="1.5625" strokeLinecap="round" />
    <circle cx="18.75" cy="12.5" r="3.90625" stroke="#8243AF" strokeWidth="1.5625" strokeLinecap="round" />
    <circle cx="32.8125" cy="29.6875" r="3.90625" stroke="#8243AF" strokeWidth="1.5625" strokeLinecap="round" />
  </svg>
);

IconPeoples.defaultProps = {
  className: null,
};
