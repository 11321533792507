import React from 'react';

type Props = {
  className?: string,
};

export const IconMaintenance = ({ className }: Props) => (
  <svg className={className} width="147" height="148" viewBox="0 0 147 148" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2831 18.9276L9.73364 36.8947L18.3465 45.2277C14.8178 52.0771 12.6477 59.5452 11.9558 67.2207L0.198434 69.6536L2.14568 93.1007L14.2205 92.9623C16.5156 100.32 20.1947 107.172 25.0601 113.151L18.4561 123.153L36.4361 137.713L44.7644 129.107C51.629 132.611 59.0941 134.784 66.7653 135.512L69.0582 147.285L92.0817 144.874L92.0167 132.868C99.3473 130.516 106.187 126.849 112.2 122.044L122.06 128.67L136.61 110.703L128.145 102.354C131.566 95.4622 133.73 88.0146 134.536 80.3611L146.293 77.9284L143.865 54.9067L131.872 55.1117C129.565 47.7594 125.888 40.9093 121.033 34.9233L127.719 24.9877L109.739 10.4278L101.329 18.967C94.4687 15.4511 87.0013 13.277 79.3277 12.5617L76.9525 0.721966L54.0115 3.19999L54.0762 15.2063C46.7195 17.4958 39.8689 21.1696 33.8926 26.0299L24.2831 18.9276ZM93.2744 49.3935C98.1458 53.3383 101.739 58.638 103.599 64.6225C105.46 70.6069 105.504 77.0073 103.726 83.0142C101.949 89.0212 98.429 94.3649 93.6124 98.3696C88.7958 102.374 82.8986 104.86 76.6666 105.513C70.4346 106.166 64.1477 104.956 58.6009 102.037C53.054 99.1173 48.4965 94.6196 45.5044 89.1122C42.5123 83.6048 41.22 77.335 41.7912 71.0959C42.3623 64.8568 44.7713 58.9285 48.7132 54.0606C53.9992 47.533 61.6637 43.3741 70.0206 42.4989C78.3774 41.6236 86.742 44.1036 93.2744 49.3935Z" fill="white" fillOpacity="0.15" />
  </svg>
);

IconMaintenance.defaultProps = {
  className: null,
};
