import React, { useState } from 'react';
import classNames from 'classnames';

type Props = {
  alt: string,
  src: string,
  className?: string,
};

export const Img = ({
  alt, src, className, ...props
}: Props) => {
  const [isLoaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      <img
        {...props}
        src={src.replace(/images/g, 'thumbnails')}
        alt={alt}
        className={classNames(className, 'thumbnail', { 'd-none': isLoaded })}
      />
      <img
        {...props}
        src={src}
        alt={alt}
        className={classNames(className, { 'd-none': !isLoaded })}
        onLoad={onLoad}
      />
    </>
  );
};

Img.defaultProps = {
  className: null,
};
