import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { MainScreen } from '../../components/main-screen/main-screen';
import { PAGE_NAMES } from '../../constants';
import { EmployeeInfoBlock } from '../../components/employee-info-block/employee-info-block';
import { TraforyAdvantages } from '../../components/trafory-advantages/trafory-advantages';

export const Employees = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.EMPLOYEES} />
    <div className="main-container">
      <MainScreen page={PAGE_NAMES.EMPLOYEES} />
      <EmployeeInfoBlock />
      <TraforyAdvantages />
    </div>
  </div>
);
