import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { localeKeys, ROUTES } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';
import { Img } from '../img/img';

import './hr-insturments.scss';

const items = [
  {
    id: 0,
    itemsCount: 2,
    link: ROUTES.LEARNING_MANAGEMENT_SYSTEM,
    anchor: '0',
  },
  {
    id: 1,
    itemsCount: 2,
    link: ROUTES.LEARNING_MANAGEMENT_SYSTEM,
    anchor: '1',
  },
  {
    id: 2,
    itemsCount: 2,
    link: ROUTES.LEARNING_MANAGEMENT_SYSTEM,
    anchor: '2',
  },
  {
    id: 3,
    itemsCount: 2,
    link: ROUTES.EVALUATION_TOOLS,
    anchor: '0',
  },
  {
    id: 4,
    itemsCount: 1,
    link: ROUTES.HUMAN_RESOURCE_MANAGEMENT,
    anchor: '0',
  },
];

export const HrInstruments = () => {
  const { t } = useTranslation();

  return (
    <div className="hr-instruments pt-5 pt-sm-0 px-lg-18">
      <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-10 text-center text-sm-start mx-sm-3 mx-lg-2">
        {t(localeKeys.traforyInstrumentsTitle)}
      </div>
      <div>
        {items.map(({
          id, itemsCount, link, anchor,
        }, index) => (
          <div
            className={classNames('hr-instruments-item d-flex flex-column mb-6 mb-sm-10 mb-md-7_5', {
              'flex-sm-row ps-sm-3 pe-sm-1_5 pe-lg-0 ps-lg-2': index % 2 !== 0,
              'flex-sm-row-reverse ps-sm-1_5 pe-sm-3 pe-lg-2 ps-lg-0': index % 2 === 0,
            })}
            key={id}
          >
            <div className="d-flex flex-column px-2 px-sm-0">
              <div className="text-medium font-weight-bold text-uppercase text-primary mb-0_5 mb-md-2 mb-lg-2_5 md-h4">
                {t(`hr-instruments.item${id}.title`)}
              </div>
              <ul className="ps-2 ps-md-3 mb-1_5 mb-sm-2 mb-md-3 text-md-main2">
                {makeNumbersArray(itemsCount).map((i) => (
                  <li key={i}>{t(`hr-instruments.item${id}.description${i}`)}</li>
                ))}
              </ul>
              <AnchorLinkSmooth
                direction="center"
                className="align-self-center align-self-sm-start"
                to={`${link}#${anchor}`}
              >
                <Button
                  className="mb-1_5 mb-sm-0"
                  type="primary"
                  size="large"
                >
                  {t(localeKeys.more)}
                </Button>
              </AnchorLinkSmooth>
            </div>
            <Img
              className="hr-instruments-item-image mt-md-2 align-self-center align-self-sm-start"
              alt={`screen${id}`}
              src={`images/hr-instruments/frame${id + 1}.webp`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
