import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';
import { LandingTabs } from '../landing-tabs/landing-tabs';

import './trafory-purpose.scss';

export const TraforyPurpose = () => {
  const { t } = useTranslation();

  return (
    <div className="trafory-purpose bg-primary bg-opacity-5 d-flex flex-column align-items-start
      pb-10_25 pb-sm-5 px-lg-3 pt-2_5 pt-sm-2 pt-lg-4 pb-md-5 mx-sm-3 mx-lg-auto mb-sm-10"
    >
      <div className="d-flex justify-content-center justify-content-md-start align-items-center w-100 px-2 px-md-2_5">
        <div className="text-center mb-2 mb-md-2_5 mb-lg-4_5 h5 md-h1 text-sm-start w-100">
          {t(localeKeys.traforyPurposeTitle)}
        </div>
      </div>
      <LandingTabs className="w-100" />
    </div>
  );
};
