import React from 'react';
import { useTranslation } from 'react-i18next';
import { BitrixForm } from '../../bitrix-form/bitrix-form';
import { FORM_TYPES, localeKeys } from '../../../constants';
import { IconLogo } from '../../icons/icon-logo';
import { TraforySocials } from '../../trafory-socials/trafory-socials';
import { IconQuestion } from '../../icons/icons-main-screen/icon-question';
import { IconBranches } from '../../icons/icons-main-screen/icon-branches';
import { IconPhone } from '../../icons/icons-main-screen/icon-phone';
import { IconBranch } from '../../icons/icons-main-screen/icon-branch';
import { IconSettings } from '../../icons/icons-main-screen/icon-settings';
import { IconBell } from '../../icons/icon-bell';

import './technical-capabilities-support.scss';

const icons = [
  {
    id: 0,
    Icon: IconQuestion,
  }, {
    id: 1,
    Icon: IconPhone,
  }, {
    id: 2,
    Icon: IconBranches,
  }, {
    id: 3,
    Icon: IconBranch,
  }, {
    id: 4,
    Icon: IconSettings,
  }, {
    id: 5,
    Icon: IconBell,
  },
];

export const TechnicalCapabilitiesSupport = () => {
  const { t } = useTranslation();

  return (
    <div className="technical-capabilities-support d-flex flex-column flex-sm-row px-lg-15
      align-items-center align-items-sm-start text-center text-sm-start position-relative px-sm-3"
    >
      <div>
        <IconLogo linkContainerClassName="mt-6_25 mt-sm-14 mt-md-6" />
        <div className="h5 md-h2 mb-0_5 text-primary mb-sm-1 mb-md-1_25 text-lowercase">
          {t(localeKeys.technicalCapabilitiesTitle)}
        </div>
        <div className="text-medium mx-3 mx-sm-0 md-h5 font-weight-normal mb-0 pe-md-5 pe-lg-10">
          {t(localeKeys.technicalCapabilitiesSupportHint)}
        </div>
      </div>
      <BitrixForm
        className="pt-2_5 pt-sm-4 pt-md-2 pt-lg-5 pb-5_25 px-2 pb-md-11 px-sm-0 ms-sm-5 ms-md-0"
        formType={FORM_TYPES.SUPPORT}
      />
      <TraforySocials className="d-sm-none mb-4" />
      {icons.map(({ Icon, id }) => (
        <Icon
          key={id}
          className={`technical-capabilities-support-icon technical-capabilities-support-icon-${id + 1}`}
        />
      ))}
    </div>
  );
};
