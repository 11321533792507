import React from 'react';

type Props = {
  className?: string,
};

export const IconBell = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="bell">
      <path id="Duo" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M16.3048 18.3148C16.6833 18.4831 16.8538 18.9263 16.6855 19.3048L16.4352 19.868C15.6563 21.6206 13.9182 22.7502 12.0002 22.7502C10.0822 22.7502 8.3441 21.6206 7.56513 19.868L7.31483 19.3048C7.1466 18.9263 7.31707 18.4831 7.69558 18.3148C8.0741 18.1466 8.51732 18.3171 8.68555 18.6956L8.93584 19.2588C9.47406 20.4697 10.675 21.2502 12.0002 21.2502C13.3254 21.2502 14.5263 20.4697 15.0645 19.2588L15.3148 18.6956C15.4831 18.3171 15.9263 18.1466 16.3048 18.3148Z" fill="#3396AA" />
      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M4.08826 7.7364C4.84257 3.96483 8.15414 1.25 12.0004 1.25C15.8466 1.25 19.1582 3.96483 19.9125 7.7364L21.1663 14.0054C21.569 16.019 20.5001 18.0431 18.6328 18.873C14.4382 20.7372 9.56254 20.7372 5.36796 18.873C3.50065 18.0431 2.43174 16.019 2.83447 14.0054L4.08826 7.7364ZM18.0236 17.5023C19.2588 16.9533 19.9586 15.6151 19.6954 14.2995L18.4417 8.03057C17.8276 4.96014 15.1316 2.75 12.0004 2.75C8.86916 2.75 6.17322 4.96014 5.55913 8.03057L4.30534 14.2995C4.04222 15.6151 4.74199 16.9533 5.97717 17.5023C9.7839 19.1942 14.2169 19.1942 18.0236 17.5023Z" fill="#3396AA" />
    </g>
  </svg>
);

IconBell.defaultProps = {
  className: null,
};
