import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { localeKeys, ROUTES } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';
import { Img } from '../img/img';

import './trafory-instruments.scss';

const items = [
  {
    name: 'hr-management',
    listItems: makeNumbersArray(5),
    link: ROUTES.EVALUATION_TOOLS,
  },
  {
    name: 'employees-education',
    listItems: makeNumbersArray(5),
    link: ROUTES.LEARNING_MANAGEMENT_SYSTEM,
  },
  {
    name: 'analytics',
    listItems: makeNumbersArray(4),
  },
  {
    name: 'hr-processes',
    listItems: makeNumbersArray(4),
    link: ROUTES.HUMAN_RESOURCE_MANAGEMENT,
  },
  {
    name: 'organization',
    listItems: makeNumbersArray(3),
  },
];

export const TraforyInstruments = () => {
  const { t } = useTranslation();

  return (
    <div className="trafory-instruments pb-2_5 pt-7_5 pt-md-10 mx-auto">
      <div className="trafory-instruments-title h5 md-h1 mb-2 mb-md-5 text-center text-sm-start  ps-2 ps-md-5_5 ps-lg-0 ms-md-auto mx-auto">
        {t(localeKeys.traforyInstrumentsTitle)}
      </div>
      <div className="p-5 d-grid gap-2 m-auto justify-content-center">
        {
          items.map(({ name, listItems, link }, index) => (
            <div key={name} className="trafory-instruments-card d-flex flex-column flex-md-row-reverse justify-content-md-end mb-md-7_5">
              <div className="d-flex flex-column">
                <ul className="ps-2 ps-md-3 trafory-instruments-list-items mb-2 mb-md-3_5" key={name}>
                  <div className="trafory-instruments-list-items-title text-medium text-primary
                    d-flex mb-1 md-h4 font-weight-normal font-weight-md-normal mb-md-1_5 font-weight-md-bold"
                  >
                    0
                    {index + 1}
                    {' '}
                    <div className="font-weight-bold d-inline-block ms-1">
                      {t(`trafory-instruments.${name}.title`)}
                    </div>
                  </div>
                  {listItems.map((i) => (
                    <li className="text-md-main2" key={`${name}-${i}`}>
                      <span className="trafory-instruments-list-items-item-text position-relative">
                        {t(`trafory-instruments.${name}.text-${i + 1}`)}
                      </span>
                    </li>
                  ))}
                </ul>

                {link && (
                  <Link className="align-self-center align-self-md-start" to={link}>
                    <Button
                      type="primary"
                      size="large"
                    >
                      {t('btn.more')}
                    </Button>
                  </Link>
                )}
              </div>

              <Img
                className="trafory-instruments-card-image mt-2 mb-5 mt-md-0_5 me-md-8_5"
                alt={name}
                src={`images/trafory-instruments/${name}.webp`}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};
