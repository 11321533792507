import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { BlogService } from '../services/blog';
import { Post, Tag } from '../types/blog';

class BlogStore {
  isLoading = false;

  posts: Post [] = [];

  tags: Tag[] = [];

  post: Post | null = null;

  competencyModelImage?: string;

  async getTags() {
    this.isLoading = true;
    try {
      this.tags = await BlogService.getTags();
    } finally {
      this.isLoading = false;
    }
  }

  async getAllPosts() {
    this.isLoading = true;

    try {
      this.posts = await BlogService.getAllPosts();
    } finally {
      this.isLoading = false;
    }
  }

  async getPostsByTag(tag: number) {
    this.isLoading = true;

    try {
      this.posts = await BlogService.getPostsByTag(tag);
    } finally {
      this.isLoading = false;
    }
  }

  async getPostByUrl(url: string) {
    this.isLoading = true;

    try {
      this.post = await BlogService.getPostByUrl(url);
    } finally {
      this.isLoading = false;
    }
  }

  async getCompetencyModelImage() {
    this.competencyModelImage = await BlogService.getCompetencyModelImage();
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new BlogStore();

export const useBlogStore = () => useContext<typeof store>(createContext(store));
