import i18n from 'i18next';
import http from './http-service';
import { ReviewData } from '../types/reviews';

export const ReviewsService = {
  getReviews: async () => {
    const result = await http.get(`/api/reviews?locale=${i18n.resolvedLanguage}`);

    return result.data?.data.map(({ attributes, id }: ReviewData) => ({
      ...attributes,
      id,
    }));
  },
};
