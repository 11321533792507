import React from 'react';

type Props = {
  className?: string
};

export const IconTime = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.5" stroke="white" />
    <path d="M16.5 12H12.25C12.1119 12 12 11.8881 12 11.75V8.5" stroke="white" strokeLinecap="round" />
  </svg>
);

IconTime.defaultProps = {
  className: null,
};
