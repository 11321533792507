import React from 'react';

type Props = {
  className?: string,
};

export const IconStack = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#4094DA" fillOpacity="0.1" />
    <path d="M16 8L9 11L16 14L23 11L16 8Z" stroke="#4094DA" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6601 14.3159L8.80304 15.5404C8.6192 15.6192 8.5 15.7999 8.5 15.9999C8.5 16.2 8.6192 16.3807 8.80304 16.4595L15.803 19.4595C15.9288 19.5134 16.0712 19.5134 16.197 19.4595L23.197 16.4595C23.3808 16.3807 23.5 16.2 23.5 15.9999C23.5 15.7999 23.3808 15.6192 23.197 15.5404L20.3399 14.3159L19.0706 14.8599L21.7307 15.9999L16 18.456L10.2693 15.9999L12.9294 14.8599L11.6601 14.3159Z" fill="#4094DA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6601 19.3159L8.80304 20.5404C8.6192 20.6192 8.5 20.7999 8.5 20.9999C8.5 21.2 8.6192 21.3807 8.80304 21.4595L15.803 24.4595C15.9288 24.5134 16.0712 24.5134 16.197 24.4595L23.197 21.4595C23.3808 21.3807 23.5 21.2 23.5 20.9999C23.5 20.7999 23.3808 20.6192 23.197 20.5404L20.3399 19.3159L19.0706 19.8599L21.7307 20.9999L16 23.456L10.2693 20.9999L12.9294 19.8599L11.6601 19.3159Z" fill="#4094DA" />
  </svg>
);

IconStack.defaultProps = {
  className: null,
};
