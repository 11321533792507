import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { PAGE_NAMES } from '../../constants';
import { CompetencyModelView } from '../../components/competency-model-view/competency-model-view';

export const CompetencyModel = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.COMPETENCY_MODEL} />
    <CompetencyModelView />
  </div>
);
