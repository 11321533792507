import React, { memo, useEffect, useRef } from 'react';
import traforyPlatformPolicy from '../../static/docs/policy-platform.pdf';

export const TraforyPolicy = memo(() => {
  const ref: React.MutableRefObject<HTMLAnchorElement | null> = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, []);

  return (
    <a href={traforyPlatformPolicy} rel="noreferrer" ref={ref}>Политика конфиденциальности Trafory Platform</a>
  );
});
