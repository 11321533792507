import React, { useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { marked } from 'marked';
import { observer } from 'mobx-react-lite';
import { localeKeys } from '../../constants';
import { useReviewsStore } from '../../stores/reviews-store';
import { Review } from '../../types/reviews';
import { IconBriefcase } from '../icons/icons-possibilities/icon-briefcase';

import './reviews.scss';

export const Reviews = observer(() => {
  const { t, i18n } = useTranslation();
  const store = useReviewsStore();
  const scrollContainerRef: any = useRef();

  const setScroll = (e: any) => {
    scrollContainerRef.current?.scrollTo({ left: e.currentTarget.offsetLeft - e.currentTarget.clientWidth / 2, behavior: 'smooth' });
  };

  useEffect(() => {
    store.getAllReviews();

    return () => {
      store.reviews = [];
    };
  }, [i18n]);

  return (
    <div className="reviews pt-7_5 pt-sm-10">
      <div className="h5 md-h1 text-center text-sm-start ms-sm-3 mb-0 ms-md-5_5 ms-lg-15">
        {t(localeKeys.reviews)}
      </div>
      <div className="position-relative reviews-content">
        {store.isLoading ? <Spin /> : !!store.reviews && (
          <>
            <div className="reviews-circle position-absolute rounded-circle bg-primary z-0 mt-md-1_75 mt-lg-3_75" />
            <div
              ref={scrollContainerRef}
              className="reviews-container position-relative d-flex px-3 pt-2 pt-md-3 pt-lg-5 pb-4 pb-md-3 px-md-5 ps-lg-15"
            >
              {store.reviews.map(({
                title, text, name, position,
              }: Review) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  onClick={setScroll}
                  className="reviews-item bg-white position-relative z-1 p-2 p-md-3 d-flex flex-column"
                >
                  <div className="d-flex align-items-center mb-1 mb-md-2">
                    <div className="p-1_25 p-md-1_5 bg-primary rounded-3">
                      <IconBriefcase className="reviews-item-icon" />
                    </div>
                    <div className="ms-1 font-weight-medium md-h5 font-weight-bold">
                      {title}
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(text || ''),
                    }}
                    className="text-small text-md-medium2 line-height-1_4 reviews-item-text"
                  />
                  <div className="text-end mt-auto">
                    <div className="text-primary font-weight-bold mb-0_5 text-md-medium">
                      {name}
                    </div>
                    <div className="text-small text-night-500 line-height-1 reviews-item-position">
                      {position}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
});
