import React from 'react';

type Props = {
  className?: string
};

export const IconPhone = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9849 13.7627L20.8194 16.5972C21.1953 16.9731 21.1953 17.5825 20.8194 17.9584C18.7872 19.9905 15.57 20.2192 13.2709 18.4948L11.4619 17.1381C9.71837 15.8304 8.16955 14.2816 6.8619 12.5381L5.50518 10.7291C3.78082 8.42998 4.00946 5.21276 6.04163 3.18059C6.41751 2.80471 7.02693 2.80471 7.40281 3.18059L10.2373 6.01512C10.6279 6.40564 10.6279 7.0388 10.2373 7.42933L9.12018 8.54649C8.94888 8.71779 8.90641 8.97949 9.01475 9.19616C10.2673 11.7013 12.2987 13.7327 14.8038 14.9853C15.0205 15.0936 15.2822 15.0511 15.4535 14.8798L16.5707 13.7627C16.9612 13.3721 17.5944 13.3721 17.9849 13.7627Z"
      stroke="white"
    />
  </svg>
);

IconPhone.defaultProps = {
  className: null,
};
