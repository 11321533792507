import React from 'react';

type Props = {
  className?: string,
};

export const IconFlag = ({ className }: Props) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="flag-2">
      <path id="Duo" opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M10.9835 5.06443C9.63711 4.25657 8.02491 4.01667 6.50159 4.39749L5.18208 4.72737C4.78023 4.82783 4.37303 4.58351 4.27257 4.18167C4.17211 3.77982 4.41643 3.37262 4.81828 3.27216L6.13779 2.94228C8.04705 2.46497 10.0677 2.76565 11.7553 3.77819C13.2373 4.66741 15.0339 4.86462 16.6735 4.31808L19.763 3.28825C19.9917 3.21202 20.2431 3.25037 20.4387 3.39133C20.6343 3.5323 20.7502 3.75868 20.7502 3.99977V14.9998C20.7502 15.3226 20.5436 15.6092 20.2373 15.7113L17.1479 16.7411C15.0928 17.4261 12.8411 17.1789 10.9835 16.0644C9.63711 15.2566 8.0249 15.0167 6.50159 15.3975L5.18208 15.7274C4.78023 15.8278 4.37303 15.5835 4.27257 15.1817C4.17211 14.7798 4.41643 14.3726 4.81828 14.2722L6.13779 13.9423C8.04705 13.465 10.0677 13.7657 11.7553 14.7782C13.2373 15.6674 15.0339 15.8646 16.6735 15.3181L19.2502 14.4592V5.04034L17.1479 5.7411C15.0928 6.42612 12.8411 6.17894 10.9835 5.06443Z" fill="#3396AA" />
      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 1.25C5.41421 1.25 5.75 1.58579 5.75 2V22C5.75 22.4142 5.41421 22.75 5 22.75C4.58579 22.75 4.25 22.4142 4.25 22V2C4.25 1.58579 4.58579 1.25 5 1.25Z" fill="#3396AA" />
    </g>
  </svg>

);

IconFlag.defaultProps = {
  className: null,
};
