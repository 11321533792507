import React, {
  useEffect, useRef, useState,
} from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IconLogo } from '../icons/icon-logo';
import { Img } from '../img/img';
import { localeKeys } from '../../constants';
import { usePixelRatio } from '../../hooks/use-pixel-ratio';
import { IconBelarus } from '../icons/icon-belarus';
import { makeNumbersArray } from '../../helpers/numbers';
import { IconUsers } from '../icons/icons-possibilities/icon-users';
import { IconBriefcase } from '../icons/icons-possibilities/icon-briefcase';
import { IconStar } from '../icons/icon-star/icon-star';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';

import './promo-view.scss';

export const PromoView = () => {
  const { t } = useTranslation();
  const pixelRatio = usePixelRatio();
  const [isAnimation, setAnimation] = useState(true);
  const { search } = useLocation();

  const isRu = search.includes('ru');

  const containerRef = useRef<any>(null);

  const stopAnimation = () => {
    setAnimation(false);
  };

  useEffect(() => {
    const itemsContainer = containerRef.current;
    const scrollAnimation = setInterval(() => {
      if (itemsContainer) {
        if (itemsContainer.scrollLeft >= Math.round(itemsContainer.scrollWidth / 2)) {
          itemsContainer.scrollLeft = 0;
        }

        itemsContainer.scrollBy({ left: 1 });
      }
    }, 30);

    if (!isAnimation) {
      clearInterval(scrollAnimation);
      setTimeout(() => { setAnimation(true); }, 1000);
    }

    return () => {
      clearInterval(scrollAnimation);
    };
  }, [isAnimation]);

  return (
    <div className="promo px-2 py-3 px-sm-3 py-sm-4 py-md-6 position-relative pb-md-0 px-lg-15 pt-lg-8_75">
      <IconLogo
        className="main-screen-logo"
        linkContainerClassName="mx-auto text-center"
      />
      <div className="text-medium text-sm-medium2 mt-sm-2 mt-md-0 md-h5 font-weight-normal">
        {t(localeKeys.promoTitle)}
      </div>

      <AnchorLinkSmooth
        to={`${search}#bitrix-form`}
        className="mt-2 mt-sm-3 mb-1 mb-sm-9 mb-md-7 mb-md-9 d-inline-block"
      >
        <Button
          onClick={stopAnimation}
          type="primary"
          className="px-2 px-md-3_75"
          size="large"
        >
          {t(localeKeys.submitYourApplication)}
        </Button>
      </AnchorLinkSmooth>
      <Img
        className="promo-image"
        src={`images/trafory-advantages/photo${pixelRatio}.png`}
        alt="promo"
      />
      {isRu ? (
        <div className="mt-1_25 mt-sm-10 mt-md-5 mt-lg-12" />
      ) : (
        <div className="d-flex align-items-center mt-1">
          <IconBelarus />
          <div className="ms-0_5 text-primary">
            {t(localeKeys.madeInBelarus)}
          </div>
        </div>
      )}
      <div className="promo-offer-conditions text-primary text-center font-weight-bold mt-5 md-h1">
        {t(localeKeys.offerConditions)}
      </div>
      <div className="d-flex flex-column flex-sm-row gap-1_25 gap-sm-2 mt-1 mb-2 mt-sm-2 mt-md-3 mb-md-3">
        {makeNumbersArray(2).map((i) => (
          <div
            key={i}
            className="text-white bg-primary rounded-large p-3 pb-3_75 pt-md-6_25 px-md-7 pb-md-7_5
          d-flex flex-column gap-3_75 flex-grow-1"
          >
            {makeNumbersArray(2).map((item) => (
              <div key={item} className="d-flex text-medium2 md-h4 lg-h3">
                <div className="rounded-circle bg-white bg-opacity-25 d-inline-flex p-1_75 p-md-3">
                  {item === 0 ? <IconUsers className="promo-icon" /> : <IconBriefcase className="promo-icon" /> }
                </div>
                <div className="ms-1_5 d-flex flex-column justify-content-between justify-content-md-center">
                  <div className="font-weight-light">
                    {t(item === 0 ? localeKeys.employeesCount : localeKeys.oneTimePayment)}
                  </div>
                  <div className="font-weight-bold">
                    {t(`offer${i + 1}-item${item + 1}-description${isRu && item === 1 ? '-rub' : ''}`)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <div className="d-md-flex align-items-center">
          <div
            className="text-medium2 md-h4 lg-h3 font-weight-bold text-primary promo-bonus-title
            mb-0_5 me-sm-0_5 text-nowrap d-flex d-sm-inline-flex align-items-center position-relative"
          >
            <div className="p-1 p-sm-1_5 bg-primary rounded-circle d-inline-flex bg-opacity-25 me-0_5">
              <IconStar />
            </div>
            {t(localeKeys.promoBonusTitle)}
          </div>
          <span className="font-weight-bold text-medium text-nowrap text-md-medium2 text-lg-main2 ms-md-auto d-md-inline-block">
            {t(localeKeys.hrProcessesAudit)}
          </span>
          {' '}
          <span className="text-md-medium2 d-md-inline-block mx-md-1 lg-h5 font-weight-normal">
            {t(localeKeys.or)}
          </span>
          {' '}
          <div className="font-weight-bold text-medium mb-1 d-sm-inline-block promo-info-title text-md-medium2 text-lg-main2 mb-md-0">
            {t(localeKeys.informationSecurityCourse)}
          </div>
        </div>
        <div className="text-sm-end text-sm-medium">
          {t(localeKeys.paymentBefore, { date: '30.10.2023' })}
        </div>

        <div className="text-center">
          <AnchorLinkSmooth
            to={`${search}#bitrix-form`}
            className="mt-2 mb-5 mb-sm-6_25 mb-md-7_5 mx-auto d-inline-block"
          >
            <Button
              onClick={stopAnimation}
              className="px-2 px-md-3_75"
              type="primary"
              size="large"
            >
              {t(localeKeys.submitYourApplication)}
            </Button>
          </AnchorLinkSmooth>
        </div>
        <div className="mb-1 font-weight-medium text-center promo-functional-title mx-auto md-h2 mb-md-3">
          {t(localeKeys.promoFunctionalTitle)}
        </div>
        <div className="promo-items-container overflow-hidden">
          <div ref={containerRef} className="promo-items d-flex">
            {[...makeNumbersArray(5), ...makeNumbersArray(5)].map((i) => (
              <div className={`promo-item promo-item-${i} ps-3 flex-1`}>
                <div className="text-center text-primary h5 font-weight-normal mb-1_25">
                  {t(`promo.functional.item${i + 1}.title`)}
                </div>
                <div className="promo-item-inner px-3 py-2_5 bg-primary rounded-4">
                  <Img className="w-100" key={i} src={`images/promo/${i}.webp`} alt={`frame-${i + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
