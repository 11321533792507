import React from 'react';

type Props = {
  className?: string,
};

export const IconQuestion = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#3396AA" fillOpacity="0.1" />
    <circle cx="16" cy="16" r="9" stroke="#3396AA" />
    <circle cx="16" cy="22" r="0.5" fill="#3396AA" />
    <path d="M16 20V19.1432C16 18.429 16.357 17.762 16.9512 17.3659L17.5497 16.9669C18.4558 16.3628 19 15.3459 19 14.2569V14C19 12.3431 17.6569 11 16 11V11C14.3431 11 13 12.3431 13 14V14" stroke="#3396AA" />
  </svg>
);

IconQuestion.defaultProps = {
  className: null,
};
