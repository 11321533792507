import React, { useCallback } from 'react';
import { NavHashLink } from 'react-router-hash-link';

export const AnchorLinkSmooth = ({
  children, to, ...props
}: any) => {
  const scrollFunction = useCallback((el: any) => {
    window.scrollTo({ top: el.offsetTop - 100, left: 0, behavior: 'smooth' });
  }, [children, to, props]);

  if (to) {
    return (
      <NavHashLink
        to={to}
        scroll={scrollFunction}
        {...props}
      >
        {children}
      </NavHashLink>
    );
  }

  return (
    <div {...props}>
      {children}
    </div>
  );
};
