import React from 'react';

type Props = {
  className?: string,
};

export const IconMap = ({ className }: Props) => (
  <svg className={className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="35" height="35" rx="17.5" fill="#8243AF" fillOpacity="0.1" />
    <path d="M25.4062 20.6333V10.7964C25.4062 10.4305 25.0212 10.1925 24.6939 10.3561L21.2025 12.1019C21.0924 12.1569 20.9656 12.1687 20.8472 12.1349L13.5591 10.0526C13.4407 10.0188 13.3139 10.0305 13.2038 10.0856L9.27207 12.0515C9.10533 12.1348 9 12.3053 9 12.4917V22.3286C9 22.6945 9.38504 22.9325 9.7123 22.7689L13.2038 21.0231C13.3139 20.9681 13.4407 20.9563 13.5591 20.9901L20.8472 23.0724C20.9656 23.1062 21.0924 23.0944 21.2025 23.0394L25.1342 21.0735C25.3009 20.9902 25.4062 20.8197 25.4062 20.6333Z" stroke="#8243AF" strokeWidth="1.09375" />
    <path d="M21.0312 23.125V12.1875" stroke="#8243AF" strokeWidth="1.09375" />
    <path d="M13.375 20.9375V10" stroke="#8243AF" strokeWidth="1.09375" />
  </svg>
);

IconMap.defaultProps = {
  className: null,
};
