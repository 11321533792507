import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../constants';
import { makeNumbersArray } from '../../../helpers/numbers';
import { IconCheck } from '../../icons/icon-check';

import './technical-capabilities-info-block.scss';

export const TechnicalCapabilitiesInfoBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="technical-capabilities-info-block mb-10 mt-3_75 px-2 px-sm-3 px-lg-15">
      <div className="h5 md-h1 mb-2 mb-md-3 mb-lg-5 text-center text-sm-start ms-sm-1 ms-md-0">
        {t(localeKeys.technicalCapabilitiesTitle)}
      </div>
      <div className="d-flex flex-column flex-sm-row w-100 justify-content-between">
        <div>
          {makeNumbersArray(5).map((i) => {
            const currentItem = `technical-capabilities.item${i + 1}`;

            return (
              <div
                key={i}
                className="technical-capabilities-info-block-item d-flex mb-2_5 pe-1"
              >
                <div className="me-1">
                  <IconCheck className="technical-capabilities-info-block-item-icon" />
                </div>
                <div>
                  <div className="text-primary text-medium md-h5 font-weight-normal mb-0_5">
                    {t(`${currentItem}.title`)}
                  </div>
                  <div className="text-medium">
                    {t(`${currentItem}.description`, '')}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <img
          className="technical-capabilities-info-block-image align-self-center align-self-sm-end me-md-8 me-lg-0"
          alt="technical-capabilities"
          src="images/technical-capabilities/image.png"
        />
      </div>
    </div>
  );
};
