import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeNumbersArray } from '../../helpers/numbers';
import { Img } from '../img/img';

import './employee-info-block.scss';

export const EmployeeInfoBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="employee-info-block mb-10">
      {makeNumbersArray(4).map((i, index) => (
        <div
          key={i}
          className={classNames(
            'employee-info-block-item d-flex flex-column mb-8 mb-sm-13_5 mb-md-10 px-lg-10',
            'mb-md-25',
            {
              'flex-sm-row-reverse': index % 2 === 0,
              'flex-sm-row': index % 2 !== 0,
            },
          )}
        >
          <div className={classNames(
            'employee-info-block-item-text text-medium md-h4 text-uppercase',
            'text-primary font-weight-bold px-2 mb-1 mt-sm-7 mt-lg-9',
            {
              'ms-sm-3_5 ms-md-7 ms-lg-16_25': index % 2 !== 0,
            },
          )}
          >
            {t(`employee-info-block.description${i + 1}`)}
          </div>
          <Img
            className={classNames('employee-info-block-item-image', {
              'ms-sm-2': index % 2 === 0,
              'me-sm-2': index % 2 !== 0,
            })}
            alt={`frame${i + 1}`}
            src={`images/employee-info-block/frame${i + 1}.webp`}
          />
        </div>
      ))}
    </div>
  );
};
