import React from 'react';
import classNames from 'classnames';
import { Media } from 'react-breakpoints';
import { mediaBreakpoints, PAGE_NAMES } from '../../../constants';
import { Img } from '../../img/img';

type Props = {
  i: number,
  currentItem: number,
  page: string
};

export const ScreenFrame = ({ i, currentItem, page }: Props) => {
  const getFolderName = (breakpoint: number): string => {
    if (breakpoint >= mediaBreakpoints.sm && breakpoint < mediaBreakpoints.md && page === PAGE_NAMES.HOME) {
      return 'tablet';
    }

    if (breakpoint >= mediaBreakpoints.md) {
      return 'desktop';
    }

    return 'mobile';
  };

  return (
    <Media>
      {({ currentBreakpoint, breakpoints }: any) => (
        <Img
          className={classNames('position-absolute main-screen-frames-item', {
            'h-100': breakpoints[currentBreakpoint] >= mediaBreakpoints.md,
            'opacity-0': i !== currentItem,
          })}
          alt={`screenshot${i}`}
          src={`images/main-screen/${page}/${getFolderName(breakpoints[currentBreakpoint])}/frame${i}.webp`}
        />
      )}
    </Media>
  );
};
