import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';

import './trafory-partners.scss';

// const traforyPartnerIconsMobile = [
//   ['cem', 'apk', 'bm', 'ferico'],
//   ['amkodor', 'rtl', 'ds', 'life', 'raiffeisen'],
//   ['bns', 'spectr', 'agronova', 'eurasian-foods'],
// ];

const traforyPartnerIconsDesktop = [
  ['apk', 'eurasian-foods', 'bm', 'agronova', 'life', 'spectr', 'amkodor', 'bns', 'cem', 'rtl', 'ds', 'raiffeisen', 'ferico'],
];

export const TraforyPartners = () => {
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const [isAnimation, setAnimation] = useState(true);

  useEffect(() => {
    document.querySelector('.anchor-link')?.addEventListener('click', () => {
      setAnimation(false);
    });
  }, []);

  useEffect(() => {
    const itemsContainer = containerRef.current;
    const scrollAnimation = setInterval(() => {
      const isMobile = itemsContainer.firstChild.clientWidth;

      if (itemsContainer) {
        // 8/12 - half of grid-gap
        if (itemsContainer.scrollLeft - (isMobile ? 8 : 12) >= Math.round(
          // firstChild - mobile, lastChild - desktop
          (itemsContainer.lastChild.clientWidth || itemsContainer.firstChild.clientWidth) / 2,
        )) {
          itemsContainer.scrollLeft = 0;
        }

        itemsContainer.scrollBy({ left: 1 });
      }
    }, 30);

    if (!isAnimation) {
      clearInterval(scrollAnimation);
      setTimeout(() => { setAnimation(true); }, 1000);
    }

    return () => {
      clearInterval(scrollAnimation);
    };
  }, [isAnimation]);

  const getIconsLayout = (names: string[]) => (
    <div className="d-flex justify-content-between w-100 trafory-partners-icons-inner">
      {[...names, ...names].map((name) => (
        <div
          className="flex-grow-1 trafory-partners-icons-card d-flex align-items-center justify-content-center"
          key={name}
        >
          <img
            className="trafory-partners-icons-card-icon"
            alt={`icon-${name}`}
            src={`images/trafory-partners/${name}.png`}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="trafory-partners bg-white text-primary pt-2_5 pb-2_75 py-md-10">
      <div className="h5 trafory-partners-title md-h1 text-center text-sm-start ps-sm-3 ps-md-5_5 ps-lg-0 text-md-start mb-1_5 mb-md-4_5 mx-auto">
        {t(localeKeys.traforyPartnersTitle)}
      </div>
      <div className="trafory-partners-icons-container overflow-x-hidden mx-auto p-0_5" ref={containerRef}>
        <div className="d-flex trafory-partners-icons d-flex flex-column">
          {traforyPartnerIconsDesktop.map((row) => getIconsLayout(row))}
        </div>
      </div>
    </div>
  );
};
