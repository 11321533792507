import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { PAGE_NAMES } from '../../constants';
import { PromoView } from '../../components/promo-view/promo-view';

export const Promo = () => (
  <div>
    <LandingHelmet page={PAGE_NAMES.PROMO} />
    <div className="main-container">
      <PromoView />
    </div>
  </div>
);
