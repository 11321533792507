import React, { useEffect } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import {
  useLocation,
  useRoutes,
} from 'react-router-dom';

import dayjs from 'dayjs';
import { LandingHeader } from './landing-header/landing-header';
import { routes } from '../routes';
import { LandingFooter } from './landing-footer/landing-footer';
import { BitrixForm } from './bitrix-form/bitrix-form';

import { FORM_TYPES, ROUTES } from '../constants';

import './app.scss';
import { CookiesPolicy } from './cookies-policy/cookies-policy';

const App = () => {
  const router = useRoutes(routes);
  const { i18n: { language } } = useTranslation();
  const { pathname, hash } = useLocation();
  const isCompetencyModelPage = pathname.includes(ROUTES.COMPETENCY_MODEL);
  const isBlogPage = pathname.includes(ROUTES.BLOG);
  const isPromoPage = pathname.includes(ROUTES.PROMO);

  dayjs.locale(language);

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]);

  return (
    <Layout className={classNames('app text-night-900 d-flex flex-column align-items-center overflow-hidden', language)}>
      <LandingHeader />
      {router}
      {!isBlogPage && !isCompetencyModelPage && (
        <BitrixForm
          className="py-7_5 py-7_5 px-2 py-md-10 px-md-auto"
          formType={isPromoPage ? FORM_TYPES.PROMO : FORM_TYPES.DEMO}
        />
      )}
      <CookiesPolicy />
      <LandingFooter isBlogPage={isBlogPage} isPromoPage={isPromoPage} />
    </Layout>
  );
};

export default App;
