import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { localeKeys } from '../../constants';
import { IconMaintenance } from '../icon-maintenance';

import './partners-invite.scss';

export const PartnersInvite = () => {
  const { t } = useTranslation();

  return (
    <div id="partners" className="partners-invite bg-primary text-white text-center text-sm-start">
      <div
        className="main-container position-relative overflow-hidden
        px-2 px-sm-3 px-md-5_5 py-2_5 pt-md-10 pb-md-12_5 pe-md-13 px-lg-15"
      >
        <IconMaintenance className="partners-invite-icon-left position-absolute" />
        <IconMaintenance className="partners-invite-icon-right position-absolute" />
        <div className="h4 md-h1 mb-2 mb-sm-1 mb-md-3_25 position-relative">
          {t(localeKeys.partnersInviteTitle)}
        </div>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-sm-start">
          <div className="text-medium mb-1 mb-sm-2 text-md-main2 me-md-9 position-relative partners-invite-description">
            {t(localeKeys.partnersInviteDescription)}
          </div>
          <a
            href="#bitrix-form"
            className="d-inline-block align-self-sm-center mb-sm-0_5 me-md-0_75 mt-md-0_25"
          >
            <Button
              type="primary"
              size="large"
              className="bg-white bg-opacity-20 partners-invite-button"
            >
              {t(localeKeys.partnersInviteLink)}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
