import axios from 'axios';
import { AppConstants } from '../app-constants';

const http = axios.create({
  baseURL: AppConstants.server,
  timeout: 30000,
  headers: {
    Authorization: AppConstants.token,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

http.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

export default http;
