import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { MainScreen } from '../../components/main-screen/main-screen';
import { PAGE_NAMES } from '../../constants';
import { TraforyPossibilities } from '../../components/trafory-possibilities/trafory-possibilities';
import { YourBenefits } from '../../components/your-benefits/your-benefits';
import { IntegrationResults } from '../../components/integration-results/integration-results';
import { TraforyExperience } from '../../components/trafory-experience/trafory-experience';
import { MobileAppInfo } from '../../components/mobile-app-info/mobile-app-info';
// import { OurTeam } from '../../components/our-team/our-team';

export const TopManagement = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.TOP_MANAGEMENT} />
    <div className="main-container">
      <MainScreen page={PAGE_NAMES.TOP_MANAGEMENT} />
      <TraforyPossibilities />
    </div>
    <div className="bg-primary bg-opacity-5">
      <div className="main-container">
        <YourBenefits />
      </div>
    </div>
    <div className="main-container">
      <IntegrationResults />
    </div>
    <div className="bg-primary bg-opacity-5">
      <div className="main-container">
        <TraforyExperience />
      </div>
    </div>
    {/* TODO: uncomment after fill profiles
    <div className="main-container">
      <OurTeam/>
    </div>
    */}
    <MobileAppInfo />
  </div>
);
