import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IconLinkedin } from '../icons/icons-social/icon-linkedin';
import { IconFacebook } from '../icons/icons-social/icon-facebook';
import { IconInstagram } from '../icons/icons-social/icon-instagram';
import { IconTelegram } from '../icons/icons-social/icon-telegram';
import { IconPhoneBordered } from '../icons/icons-social/icon-phone-bordered';

import './trafory-socials.scss';

const socialLinks = [
  {
    title: 'telegram',
    Icon: IconTelegram,
    url: 'https://t.me/Trafory',
  },
  {
    title: 'phone',
    Icon: IconPhoneBordered,
    url: 'tel:+375 29 342 50 92',
  },
  {
    title: 'linkedin',
    Icon: IconLinkedin,
    url: 'https://www.linkedin.com/company/trafory/',
  },
  {
    title: 'facebook',
    Icon: IconFacebook,
    url: 'https://www.facebook.com/trafory/',
  },
  {
    title: 'instagram',
    Icon: IconInstagram,
    url: 'https://www.instagram.com/trafory_com/',
  },
];

type Props = {
  className?: string
};

export const TraforySocials = ({ className }: Props) => (
  <div className={classNames(className, 'trafory-socials d-flex justify-content-between')}>
    {socialLinks.map(({ Icon, url, title }) => (
      <Button key={title} href={url} type="link" target="_blank">
        <Icon className="trafory-socials-link" />
      </Button>
    ))}
  </div>
);

TraforySocials.defaultProps = {
  className: null,
};
