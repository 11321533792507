import React, { Fragment } from 'react';
import { Footer } from 'antd/lib/layout/layout';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classNames from 'classnames';
import { localeKeys } from '../../constants';
import { usePixelRatio } from '../../hooks/use-pixel-ratio';
import { IconTime } from '../icons/icons-footer/icon-time';
import { IconPhone } from '../icons/icons-footer/icon-phone';
import { IconMail } from '../icons/icons-footer/icon-mail';
import { IconLocation } from '../icons/icons-footer/icon-location';
import { IconLogo } from '../icons/icon-logo';
import { TraforySocials } from '../trafory-socials/trafory-socials';
import policyLanding from '../../static/docs/policy-landing.pdf';
import policyCookie from '../../static/docs/policy-cookie.pdf';

import './landing-footer.scss';

const footerInfo = [
  {
    title: 'time',
    Icon: IconTime,
    description: localeKeys.footerInfoTime,
  },
  {
    title: 'phone',
    link: 'tel:+375 29 342 50 92',
    Icon: IconPhone,
    description: localeKeys.footerInfoPhone,
  },
  {
    title: 'mail',
    link: 'mailto:sales@trafory.com',
    Icon: IconMail,
    description: localeKeys.footerInfoMail,
  },
  {
    title: 'location',
    Icon: IconLocation,
    description: localeKeys.footerInfoLocation,
  },
];

const appLinks = [
  {
    title: 'google play',
    imageUrl: `${window.location.origin}/images/footer/app-links/google-play/google-play`,
    linkUrl: 'https://play.google.com/store/apps/details?id=by.dots.trafory_app',
  },
  {
    title: 'app store',
    imageUrl: `${window.location.origin}/images/footer/app-links/app-store/app-store`,
    linkUrl: 'https://apps.apple.com/by/app/trafory-business/id1541718498',
  },
  {
    title: 'app gallery',
    imageUrl: `${window.location.origin}/images/footer/app-links/app-gallery/app-gallery`,
    linkUrl: 'https://appgallery.huawei.com/app/C107158101',
  },
];

const footerInfoLinks = [
  {
    name: policyCookie,
    localeKey: localeKeys.footerInfoLinksCookiesPolicy,
  },
  {
    name: policyLanding,
    localeKey: localeKeys.footerInfoPersonalDataPolicy,
  },
  {
    name: 'career',
    localeKey: localeKeys.footerInfoLinksCareer,
  },
  {
    name: 'about',
    localeKey: localeKeys.footerInfoLinksAbout,
  },
];

type Props = {
  isBlogPage: boolean,
  isPromoPage: boolean,
};

export const LandingFooter = ({ isBlogPage, isPromoPage }: Props) => {
  const pixelRatio = usePixelRatio();
  const { t } = useTranslation();

  return (
    <Footer
      id="footer"
      className={classNames(
        'landing-footer mt-auto w-100',
        'pb-sm-6 pb-md-7_5 px-sm-3',
        {
          'bg-primary text-white': !isBlogPage,
          'blog-page bg-white': isBlogPage,
          'pt-sm-2_5 pt-md-5 pt-lg-10': !isPromoPage,
          'pt-0': isPromoPage,
        },
      )}
    >
      <div className="landing-footer-inner-container text-center d-flex flex-column align-items-center mx-auto">
        {!isBlogPage && !isPromoPage && (
          <div className="landing-footer-title align-self-sm-start h5
            mb-2_5 mb-sm-2 mb-md-2_5 mx-2 md-h1 mx-sm-0 mx-md-2_5 mx-lg-0"
          >
            <Trans>{localeKeys.footerTitle}</Trans>
          </div>
        )}

        {!isBlogPage && !isPromoPage && (
          <div className="d-flex w-100 flex-column align-items-center px-md-2_5 px-lg-0 pe-md-0
            flex-sm-row justify-content-sm-between align-items-sm-stretch"
          >
            <div className="d-flex flex-column align-items-center
            align-items-sm-start flex-grow-1 justify-content-sm-between"
            >
              <div className="landing-footer-description mb-1 mb-md-1_5 mt-md-0_5 text-md-main2 lg-h4 font-weight-normal">
                <Trans>{localeKeys.footerDescription}</Trans>
              </div>

              <div className="landing-footer-photos d-flex justify-content-flex-start mb-sm-auto">
                {[1, 2].map((item) => {
                  const name = t(`footer.photos.${item}.name`);
                  const url = `images/footer/photos/photo${item}/photo${item}${pixelRatio}.png`;

                  return (
                    <div
                      className="landing-footer-photos-item d-flex flex-column align-items-center me-lg-3_75"
                      key={item}
                    >
                      <img
                        className="w-100 mb-0_5 mb-md-1"
                        alt={name}
                        src={url}
                      />

                      <span className="text-md-main2">
                        {name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="landing-footer-contacts d-flex flex-column
                align-items-start mt-3 mt-md-7_5 mb-0_5 d-sm-grid ps-sm-1 mb-sm-0"
              >
                {footerInfo.map(({
                  Icon, description, title, link,
                }) => (
                  <a
                    href={link}
                    className="text-decoration-none text-white"
                    style={{ cursor: link ? 'pointer' : 'auto' }}
                  >
                    <div
                      key={description}
                      className={`mb-2 d-flex align-items-center landing-footer-contacts-item-${title} mb-md-0`}
                    >
                      <Icon className="me-1 me-sm-2" />
                      <span className="landing-footer-contacts-item-text md-h5 font-weight-normal">{t(description)}</span>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className="landing-footer-location-map position-relative mt-sm-3_5 mt-md-1_5">
              <a href="#bitrix-form">
                <Button
                  type="primary"
                  size="large"
                  className="landing-footer-location-map-button position-absolute px-3_5 px-md-7_5"
                >
                  <Trans>
                    {localeKeys.contact}
                  </Trans>
                </Button>
              </a>
            </div>
          </div>
        )}
        <div
          className={classNames('landing-footer-bottom px-2 w-100 text-start px-sm-0', {
            'pt-5 pt-md-8 pt-lg-9_75 pt-sm-5_5': !isPromoPage,
            'pt-3 pt-sm-5': isPromoPage,
          })}
        >
          <IconLogo className={isBlogPage ? 'fill-primary' : ''} />
          <div className="d-sm-flex justify-content-between mt-sm-2">
            <div className="landing-footer-bottom-app-links mt-2 mb-4 mt-sm-0 mb-sm-0 d-flex justify-content-between">
              {appLinks.map(({ linkUrl, imageUrl, title }) => (
                <Button
                  key={title}
                  type="link"
                  className="landing-footer-bottom-app-link me-sm-1_5 me-md-2"
                  target="_blank"
                  href={linkUrl}
                >
                  <img
                    alt={title}
                    key={title}
                    src={`${imageUrl}${pixelRatio}.png`}
                    height="100%"
                  />
                </Button>
              ))}
            </div>
            <TraforySocials className="landing-footer-bottom-social-links
              mx-auto mb-4 mb-sm-0 me-sm-0 align-items-md-center align-items-md-center"
            />
          </div>
        </div>
        <div className="landing-footer-bottom-info-links d-flex align-self-sm-start
        flex-column align-items-center text-sm-start d-sm-block line-height-1 mt-sm-3_5 mt-md-6_25 mt-lg-6"
        >
          {footerInfoLinks.map(({ name, localeKey }) => (
            <Fragment key={name}>
              <Button
                href={name}
                type="link"
                className={classNames(`text-small mb-1 mb-sm-0 me-sm-2 info-link-${name}`, {
                  'text-white': !isBlogPage,
                  'text-black': isBlogPage,
                })}
              >
                <Trans>
                  {localeKey}
                </Trans>
              </Button>
              {name === 'support' && <br className="d-none d-md-block" />}
            </Fragment>
          ))}
          <span className="text-small">
            {t(localeKeys.unidevCopyright)}
          </span>
        </div>
      </div>
    </Footer>
  );
};
