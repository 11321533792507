import React from 'react';
import { Drawer, Button } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { AnchorLinkSmooth } from '../../anchor-link/anchor-link';

import './landing-header-menu.scss';

type Props = {
  isHeaderMenuOpened: boolean,
  closeHeaderMenu: ()=> void,
  isBlogPage: boolean,
  isPromoPage: boolean,
};

const getHeaderMenuItems = (isBlogPage: boolean) => [
  {
    title: 'menu.main',
    description: 'menu.main.description',
    link: ROUTES.HOME,
  },
  {
    title: 'menu.business',
    link: ROUTES.TOP_MANAGEMENT,
    anchor: '',
    links: [
      ROUTES.TOP_MANAGEMENT,
      ROUTES.HR,
      ROUTES.EMPLOYEES,
      ROUTES.TECHNICAL_CAPABILITIES,
    ],
  },
  {
    title: 'menu.instruments',
    link: ROUTES.LEARNING_MANAGEMENT_SYSTEM,
    anchor: '',
    links: [
      ROUTES.LEARNING_MANAGEMENT_SYSTEM,
      ROUTES.EVALUATION_TOOLS,
      ROUTES.HUMAN_RESOURCE_MANAGEMENT,
    ],
  },
  {
    title: 'menu.partners',
    description: 'menu.partners.description',
    link: `${ROUTES.HOME}#partners`,
  },
  {
    title: 'menu.blog',
    anchor: '',
    link: ROUTES.BLOG,
    links: [
      `${ROUTES.BLOG}/company-cases`,
      `${ROUTES.BLOG}/events`,
      `${ROUTES.BLOG}/news`,
      `${ROUTES.BLOG}/posts`,
    ],
  },
  {
    title: 'menu.contacts',
    description: 'menu.contacts.description',
    link: `${isBlogPage ? ROUTES.HOME : ''}#footer`,
  },
];

export const LandingHeaderMenu = ({
  isHeaderMenuOpened, closeHeaderMenu, isBlogPage, isPromoPage,
}: Props) => {
  const bitrixUrl = `${isBlogPage ? ROUTES.HOME : ''}#bitrix-form`;

  return (
    <Drawer
      width={504}
      open={isHeaderMenuOpened}
      onClose={closeHeaderMenu}
    >
      {getHeaderMenuItems(isBlogPage).map(({
        title, description, links, link,
      }) => (
        <div key={title} className="mb-2 pt-1 pt-md-0 mb-md-3 d-flex flex-column">
          <AnchorLinkSmooth
            target={isPromoPage ? '_blank' : undefined}
            to={link}
            direction={link === bitrixUrl ? 'start' : 'center'}
            style={{ cursor: link ? 'pointer' : 'text' }}
            className="h5 md-h3 text-primary mb-0 text-decoration-none"
          >
            <Trans>
              {`${title}.title`}
            </Trans>
          </AnchorLinkSmooth>
          {links ? (
            <div className="d-flex flex-column align-items-start mb-2 mb-md-3 ps-2">
              {links.map((url, index) => (
                <Link key={url} to={url}>
                  <Button
                    type="link"
                    className="text-primary ms-3 p-0 mt-1 mb-0_5 mb-md-1"
                  >
                    <Trans>
                      {`${title}.link-${index + 1}`}
                    </Trans>
                  </Button>
                </Link>
              ))}
            </div>
          ) : (
            <span className="text-night-500 text-small text-md-medium mt-0_5 mt-md-0 d-inline-block">
              <Trans>
                {description}
              </Trans>
            </span>
          )}
        </div>
      ))}
    </Drawer>
  );
};
