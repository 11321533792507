import React from 'react';

type Props = {
  className?: string,
};

export const IconMail = ({ className }: Props) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#4094DA" fillOpacity="0.1" />
    <rect x="8" y="10" width="16" height="12" rx="2" stroke="#4094DA" strokeWidth="1.5" />
    <path d="M8 13L15.1056 16.5528C15.6686 16.8343 16.3314 16.8343 16.8944 16.5528L24 13" stroke="#4094DA" strokeWidth="1.5" />
  </svg>
);

IconMail.defaultProps = {
  className: null,
};
