import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { BlogContent } from '../../components/blog/blog-content/blog-content';
import { PAGE_NAMES } from '../../constants';

export const Blog = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.BLOG} />
    <div className="main-container">
      <BlogContent />
    </div>
  </div>
);
