import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { store } from '../../stores/blog-store';
import { AppConstants } from '../../app-constants';
import { BitrixForm } from '../bitrix-form/bitrix-form';
import { FORM_TYPES } from '../../constants';

import './competency-model-view.scss';

export const CompetencyModelView = observer(() => {
  useEffect(() => {
    store.getCompetencyModelImage();
  }, []);

  return (
    <div className="competency-model">
      <div className="d-flex py-7_5 py-7_5 px-2 py-md-10 px-md-auto
        align-items-center align-items-sm-start justify-content-center flex-wrap"
      >
        <BitrixForm
          className="mb-2"
          formType={FORM_TYPES.COMPETENCY_MODEL}
        />
        <img
          className="mt-md-0 ms-sm-2 ms-md-4 competency-model-image"
          alt="competency-model"
          src={`${AppConstants.server}${store.competencyModelImage}`}
        />
      </div>
    </div>
  );
});
