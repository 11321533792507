import React, { useEffect } from 'react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BlogTags, localeKeys, ROUTES } from '../../../constants';
import { useBlogStore } from '../../../stores/blog-store';
import { AppConstants } from '../../../app-constants';
import { readingTime } from '../../../helpers/time';

import './blog-info.scss';

export const BlogInfo = observer(() => {
  const { t, i18n: { language } } = useTranslation();
  const store = useBlogStore();

  useEffect(() => {
    store.getAllPosts();
  }, [language]);

  const minutes = t(localeKeys.minutesShort);

  return (
    <div className="blog-info pt-7 pb-8_5 py-sm-10 ps-sm-1 ps-md-3 ps-lg-0 mx-auto">
      <div className="d-flex justify-content-between align-items-end mb-2 mb-md-3 mb-md-5 pe-2 pe-sm-3 pe-lg-0">
        <div className="h5 ms-2 md-h1 mb-0 text-capitalize ms-md-2_5 ms-lg-0">
          {t(localeKeys.blog)}
        </div>
        <Link
          className="text-md-medium"
          to={ROUTES.BLOG}
        >
          {t(localeKeys.allPosts)}
        </Link>
      </div>
      <div className="blog-info-items d-flex px-2 ps-lg-0">
        {store.isLoading ? <Spin /> : !!store.posts.length
          && store.posts.map(({
            title, cover, description, tag, body, datePublished, id, url,
          }) => (
            <Link
              key={id}
              to={`${ROUTES.BLOG}/${BlogTags[tag.value]}/${url}`}
              className="blog-info-item d-flex flex-column text-truncate overflow-x-hidden text-decoration-none text-black"
            >
              {!!cover && (
              <img
                className="blog-info-item-image flex-grow-1"
                alt={title}
                src={`${AppConstants.server}${cover}`}
              />
              )}
              <div className="text-night-500 text-small d-flex mt-1 mt-md-2 text-truncate">
                <span className="text-capitalize text-nowrap">
                  {dayjs(datePublished).format('MMM DD, YYYY')}
                </span>
                <span className="mx-1">·</span>
                <div className="text-truncate d-inline-block me-0_5">
                  {tag.title}
                </div>
                <span className="ms-auto text-nowrap">
                  {readingTime(body)}
                  {' '}
                  {minutes}
                </span>
              </div>
              <div className="text-black font-weight-bold mt-1 mt-md-2 text-medium2 text-truncate">
                {tag.title}
              </div>
              <div className="text-small text-two-lines">
                {description}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
});
