import React from 'react';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';
import { makeNumbersArray } from '../../helpers/numbers';
import { usePixelRatio } from '../../hooks/use-pixel-ratio';

import './trafory-advantages.scss';
import { IconCheck } from '../icons/icon-check';

export const TraforyAdvantages = () => {
  const pixelRatio = usePixelRatio();
  const { t } = useTranslation();

  return (
    <div className="trafory-advantages p-2 p-sm-3 mx-auto px-lg-0 position-relative z-1">
      <div className="h5 md-h1 text-center text-sm-start mb-2 mb-md-3 ms-md-2_5 ms-lg-1">
        {t(localeKeys.traforyAdvantagesTitle)}
      </div>
      <div className="trafory-advantages-content d-flex flex-column bg-white overflow-hidden
      align-items-center flex-md-row align-items-md-start justify-content-between"
      >
        <div className="p-2 pb-0 pt-md-2_5 align-self-sm-start">
          {makeNumbersArray(5).map((i) => (
            <div
              className="d-flex mb-2_5"
              key={i}
            >
              <div>
                <IconCheck className="trafory-advantages-content-icon me-1" />
              </div>
              <div>
                <div className="h6 lg-h5 mb-0 text-primary font-weight-medium">
                  {t(`trafory-advantages.item${i + 1}.title`)}
                </div>
                <span className="line-height-1_3 text-lg-medium mb-0">
                  {t(`trafory-advantages.item${i + 1}.description`)}
                </span>
              </div>
            </div>
          ))}
        </div>
        <img
          className="trafory-advantages-image mt-0_5 mt-md-3 me-md-4"
          alt="trafory-advantages"
          src={`images/trafory-advantages/photo${pixelRatio}.png`}
        />
      </div>
    </div>
  );
};
