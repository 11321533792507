import React from 'react';

type Props = {
  className?: string
};

export const IconPhoneBordered = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3161 1H11.35H12.15H12.1839C13.8354 0.999996 15.1208 0.999993 16.1519 1.08424C17.1988 1.16977 18.0494 1.34588 18.8144 1.73571C20.0845 2.38285 21.1171 3.41547 21.7643 4.68556C22.1541 5.45065 22.3302 6.30124 22.4158 7.34814C22.5 8.37922 22.5 9.66461 22.5 11.316V11.35V12.15V12.184C22.5 13.8354 22.5 15.1208 22.4158 16.1519C22.3302 17.1988 22.1541 18.0494 21.7643 18.8144C21.1171 20.0845 20.0845 21.1171 18.8144 21.7643C18.0494 22.1541 17.1988 22.3302 16.1519 22.4158C15.1208 22.5 13.8354 22.5 12.184 22.5H12.15H11.35H11.316C9.66461 22.5 8.37922 22.5 7.34814 22.4158C6.30124 22.3302 5.45065 22.1541 4.68556 21.7643C3.41547 21.1171 2.38285 20.0845 1.73571 18.8144C1.34588 18.0494 1.16977 17.1988 1.08424 16.1519C0.999993 15.1208 0.999996 13.8354 1 12.1839V12.15V11.35V11.3161C0.999996 9.66462 0.999993 8.37922 1.08424 7.34814C1.16977 6.30124 1.34588 5.45065 1.73571 4.68556C2.38285 3.41547 3.41547 2.38285 4.68556 1.73571C5.45065 1.34588 6.30124 1.16977 7.34814 1.08424C8.37922 0.999993 9.66462 0.999996 11.3161 1ZM7.47029 2.57925C6.51615 2.65721 5.88493 2.80809 5.36655 3.07222C4.3787 3.57555 3.57555 4.3787 3.07222 5.36655C2.80809 5.88493 2.65721 6.51615 2.57925 7.47029C2.50058 8.43317 2.5 9.65747 2.5 11.35V12.15C2.5 13.8425 2.50058 15.0668 2.57925 16.0297C2.65721 16.9838 2.80809 17.6151 3.07222 18.1334C3.57555 19.1213 4.3787 19.9244 5.36655 20.4278C5.88493 20.6919 6.51615 20.8428 7.47029 20.9207C8.43317 20.9994 9.65747 21 11.35 21H12.15C13.8425 21 15.0668 20.9994 16.0297 20.9207C16.9838 20.8428 17.6151 20.6919 18.1334 20.4278C19.1213 19.9244 19.9244 19.1213 20.4278 18.1334C20.6919 17.6151 20.8428 16.9838 20.9207 16.0297C20.9994 15.0668 21 13.8425 21 12.15V11.35C21 9.65747 20.9994 8.43317 20.9207 7.47029C20.8428 6.51615 20.6919 5.88493 20.4278 5.36655C19.9244 4.3787 19.1213 3.57555 18.1334 3.07222C17.6151 2.80809 16.9838 2.65721 16.0297 2.57925C15.0668 2.50058 13.8425 2.5 12.15 2.5H11.35C9.65747 2.5 8.43317 2.50058 7.47029 2.57925Z"
      fill="#3396AA"
      className="filled-path"
    />
    <path
      className="stroke-path"
      d="M16.0404 13.3738L17.5702 14.9035C17.8076 15.1409 17.8076 15.5258 17.5702 15.7632C16.2867 17.0467 14.2548 17.1911 12.8027 16.102L12.0642 15.5481C10.695 14.5213 9.47874 13.305 8.45187 11.9358L7.898 11.1973C6.80894 9.74525 6.95334 7.71332 8.23682 6.42985C8.47422 6.19245 8.85912 6.19245 9.09651 6.42985L10.6262 7.95956C11.0168 8.35008 11.0168 8.98325 10.6262 9.37377L10.1812 9.81884C10.073 9.92703 10.0462 10.0923 10.1146 10.2292C10.9057 11.8114 12.1886 13.0943 13.7708 13.8854C13.9077 13.9538 14.073 13.927 14.1812 13.8188L14.6262 13.3738C15.0168 12.9832 15.6499 12.9833 16.0404 13.3738Z"
      stroke="#3396AA"
      strokeWidth="1.5"
    />
  </svg>
);

IconPhoneBordered.defaultProps = {
  className: null,
};
