import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Post, Tag } from '../../../../types/blog';
import { BlogTags, localeKeys, ROUTES } from '../../../../constants';
import { IconExpandLeft } from '../../../icons/icon-expand-left';
import { AppConstants } from '../../../../app-constants';
import { useBlogStore } from '../../../../stores/blog-store';
import { SelectedPost } from './post/selected-post';
import { readingTime } from '../../../../helpers/time';

import './posts.scss';

type Props = {
  tagInfo: Tag
};

export const Posts = observer(({ tagInfo }: Props) => {
  const store = useBlogStore();
  const { pathname } = useLocation();
  const selectedPostUrl = pathname.split('/').length === 4 ? pathname.split('/')[3] : null;
  const { t, i18n: { language } } = useTranslation();

  useEffect(() => {
    store.getPostsByTag(tagInfo.value);

    return () => {
      store.posts = [];
    };
  }, [pathname, language]);

  if (selectedPostUrl) {
    return <SelectedPost url={selectedPostUrl} />;
  }

  return (
    <div className="posts p-2 px-md-3 pt-md-5 px-lg-15">
      <div className="d-flex align-items-center mb-2 mb-md-3">
        <Link to={ROUTES.BLOG}>
          <IconExpandLeft />
        </Link>
        <div className="h5 md-h1 mb-0 ms-md-1">
          {tagInfo.title}
        </div>
      </div>
      {store.isLoading ? <Spin />
        : (
          <div className="posts-container d-flex flex-column flex-wrap flex-sm-row align-items-stretch">
            {store.posts?.map(({
              url, cover, id, title, body, datePublished,
            }: Post) => (
              <Link
                key={id}
                to={`${ROUTES.BLOG}/${BlogTags[tagInfo.value]}/${url}`}
                className="text-decoration-none d-block posts-item-link w-100"
              >
                <div className="posts-item h-100 w-100 d-flex flex-column">
                  {!!cover && (
                    <img
                      className="posts-item-image flex-grow-1"
                      alt={url}
                      src={`${AppConstants.server}${cover}`}
                    />
                  )}

                  <div className="p-1 py-md-1-25 px-md-2 pb-md-2_75 mt-auto text-truncate">
                    <div className="text-night-500 text-small d-flex text-truncate">
                      <span className="text-capitalize">
                        {dayjs(datePublished).format('MMM DD, YYYY')}
                      </span>
                      <span className="mx-1">·</span>
                      {tagInfo.title}
                      <span className="ms-auto">
                        {readingTime(body)}
                        {' '}
                        {t(localeKeys.minutesShort)}
                      </span>
                    </div>
                    <div className="posts-item-title text-black font-weight-bold mt-1 mt-md-2 text-md-medium text-two-lines">
                      {title}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
    </div>
  );
});
