import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { ReviewsService } from '../services/reviews';

class ReviewsStore {
  isLoading = false;

  reviews = [];

  async getAllReviews() {
    this.isLoading = true;

    try {
      this.reviews = await ReviewsService.getReviews();
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const store = new ReviewsStore();

export const useReviewsStore = () => useContext<typeof store>(createContext(store));
