import React from 'react';

type Props = {
  className?: string
};

export const IconTelegram = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="filled-path"
      d="M11.3161 1H11.35H12.15H12.1839C13.8354 0.999996 15.1208 0.999993 16.1519 1.08424C17.1988 1.16977 18.0494 1.34588 18.8144 1.73571C20.0845 2.38285 21.1171 3.41547 21.7643 4.68556C22.1541 5.45065 22.3302 6.30124 22.4158 7.34814C22.5 8.37922 22.5 9.66461 22.5 11.316V11.35V12.15V12.184C22.5 13.8354 22.5 15.1208 22.4158 16.1519C22.3302 17.1988 22.1541 18.0494 21.7643 18.8144C21.1171 20.0845 20.0845 21.1171 18.8144 21.7643C18.0494 22.1541 17.1988 22.3302 16.1519 22.4158C15.1208 22.5 13.8354 22.5 12.184 22.5H12.15H11.35H11.316C9.66461 22.5 8.37922 22.5 7.34814 22.4158C6.30124 22.3302 5.45065 22.1541 4.68556 21.7643C3.41547 21.1171 2.38285 20.0845 1.73571 18.8144C1.34588 18.0494 1.16977 17.1988 1.08424 16.1519C0.999993 15.1208 0.999996 13.8354 1 12.1839V12.15V11.35V11.3161C0.999996 9.66462 0.999993 8.37922 1.08424 7.34814C1.16977 6.30124 1.34588 5.45065 1.73571 4.68556C2.38285 3.41547 3.41547 2.38285 4.68556 1.73571C5.45065 1.34588 6.30124 1.16977 7.34814 1.08424C8.37922 0.999993 9.66462 0.999996 11.3161 1ZM7.47029 2.57925C6.51615 2.65721 5.88493 2.80809 5.36655 3.07222C4.3787 3.57555 3.57555 4.3787 3.07222 5.36655C2.80809 5.88493 2.65721 6.51615 2.57925 7.47029C2.50058 8.43317 2.5 9.65747 2.5 11.35V12.15C2.5 13.8425 2.50058 15.0668 2.57925 16.0297C2.65721 16.9838 2.80809 17.6151 3.07222 18.1334C3.57555 19.1213 4.3787 19.9244 5.36655 20.4278C5.88493 20.6919 6.51615 20.8428 7.47029 20.9207C8.43317 20.9994 9.65747 21 11.35 21H12.15C13.8425 21 15.0668 20.9994 16.0297 20.9207C16.9838 20.8428 17.6151 20.6919 18.1334 20.4278C19.1213 19.9244 19.9244 19.1213 20.4278 18.1334C20.6919 17.6151 20.8428 16.9838 20.9207 16.0297C20.9994 15.0668 21 13.8425 21 12.15V11.35C21 9.65747 20.9994 8.43317 20.9207 7.47029C20.8428 6.51615 20.6919 5.88493 20.4278 5.36655C19.9244 4.3787 19.1213 3.57555 18.1334 3.07222C17.6151 2.80809 16.9838 2.65721 16.0297 2.57925C15.0668 2.50058 13.8425 2.5 12.15 2.5H11.35C9.65747 2.5 8.43317 2.50058 7.47029 2.57925Z"
      fill="#3396AA"
    />
    <path
      className="filled-path"
      d="M16.9103 5.5C16.572 5.5 16.1919 5.58475 15.7472 5.75808C15.0568 6.02716 6.93571 9.75669 6.0118 10.1809C5.48358 10.4238 4.5 10.8756 4.5 11.953C4.5 12.6672 4.88691 13.178 5.64858 13.4722C6.05811 13.63 7.03076 13.9489 7.59509 14.1169C7.83448 14.188 8.08887 14.2245 8.35051 14.2245C8.86242 14.2245 9.37758 14.0865 9.78117 13.8431C9.77771 13.933 9.77997 14.0238 9.78987 14.1148C9.8507 14.6765 10.1709 15.2048 10.6458 15.5285C10.9564 15.7403 13.4934 17.5789 13.8722 17.871C14.4044 18.2824 14.9927 18.5 15.5724 18.5C16.6803 18.5 17.052 17.2627 17.231 16.6684C17.4907 15.8055 18.463 8.7884 18.5728 7.55104C18.6475 6.70312 18.3208 6.0049 17.6986 5.68285C17.4656 5.56141 17.1997 5.5 16.9103 5.5ZM16.9103 6.56992C17.0463 6.56992 17.1678 6.59426 17.2706 6.64829C17.5046 6.76972 17.6236 7.04044 17.5875 7.44969C17.4678 8.7994 16.4979 15.6503 16.2911 16.3372C16.114 16.9246 15.9275 17.4301 15.5724 17.4301C15.2173 17.4301 14.8293 17.2954 14.447 16.9996C14.0647 16.7043 11.5258 14.8628 11.1732 14.6226C10.8641 14.4118 10.5369 13.8864 10.9974 13.3782C11.3767 12.9598 14.2543 9.939 14.5001 9.67312C14.6831 9.47465 14.5976 9.23533 14.418 9.23533C14.3562 9.23533 14.2822 9.26352 14.2055 9.33146C13.9048 9.59733 9.7124 12.6316 9.34937 12.8756C9.10899 13.0371 8.73679 13.1535 8.35051 13.1535C8.18581 13.1535 8.01906 13.1322 7.85881 13.0846C7.30091 12.9182 6.35676 12.6084 5.98186 12.4639C5.6213 12.3248 5.49016 12.1916 5.49016 11.953C5.49016 11.6138 5.93277 11.3797 6.39917 11.1652C6.89179 10.9389 15.4157 7.02372 16.0825 6.76427C16.3911 6.64336 16.6734 6.56992 16.9103 6.56992Z"
      fill="#3396AA"
    />
  </svg>
);

IconTelegram.defaultProps = {
  className: null,
};
