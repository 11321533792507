import React from 'react';

type Props = {
  className?: string,
};

export const IconContact = ({ className }: Props) => (
  <svg className={className} width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="24.5" fill="#4094DA" fillOpacity="0.1" />
    <rect x="15.3125" y="12.25" width="19.9062" height="26.0312" rx="3.0625" stroke="#4094DA" strokeWidth="1.53125" />
    <path d="M12.25 19.9062H18.375" stroke="#4094DA" strokeWidth="1.53125" strokeLinecap="round" />
    <path d="M12.25 26.0312H18.375" stroke="#4094DA" strokeWidth="1.53125" strokeLinecap="round" />
    <path d="M12.25 32.1562H18.375" stroke="#4094DA" strokeWidth="1.53125" strokeLinecap="round" />
    <path d="M20.6388 29.0797C21.1256 28.3772 21.8372 27.7779 22.7153 27.3554C23.5934 26.9329 24.5978 26.707 25.6261 26.707C26.6544 26.707 27.6587 26.9329 28.5368 27.3554C29.4149 27.7779 30.1265 28.3772 30.6133 29.0797" stroke="#4094DA" strokeWidth="1.53125" strokeLinecap="round" />
    <circle cx="25.6255" cy="21.6177" r="2.47709" stroke="#4094DA" strokeWidth="1.53125" strokeLinecap="round" />
  </svg>
);

IconContact.defaultProps = {
  className: null,
};
