import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { PAGE_NAMES } from '../../constants';
import { MainScreen } from '../../components/main-screen/main-screen';
import { InstrumentsInfoBlock } from '../../components/instruments-info-block/instruments-info-block';
import { MobileAppInfo } from '../../components/mobile-app-info/mobile-app-info';
import { makeNumbersArray } from '../../helpers/numbers';

const instruments = [
  {
    id: 0,
    listItems: makeNumbersArray(4),
  },
  {
    id: 1,
    listItems: makeNumbersArray(5),
  },
  {
    id: 2,
    listItems: makeNumbersArray(4),
  },
  {
    id: 3,
    listItems: makeNumbersArray(3),
  },
  {
    id: 4,
    listItems: makeNumbersArray(4),
  },
];

export const EvaluationTools = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.EVALUATION_TOOLS} />
    <div className="main-container">
      <MainScreen
        page={PAGE_NAMES.EVALUATION_TOOLS}
      />
      <InstrumentsInfoBlock
        items={instruments}
        page={PAGE_NAMES.EVALUATION_TOOLS}
      />
    </div>
    <MobileAppInfo />
  </div>
);
