import { PageNames } from './types/pages';

export const landingLocales = {
  RU: 'ru',
  EN: 'en',
};

export const PAGES = {
  HOME: 'HOME',
  TOP_MANAGEMENT: 'TOP_MANAGEMENT',
  BUSINESS: 'BUSINESS',
  HR: 'HR',
  EMPLOYEES: 'EMPLOYEES',
  TECHNICAL_CAPABILITIES: 'TECHNICAL_CAPABILITIES',
  LEARNING_MANAGEMENT_SYSTEM: 'LEARNING_MANAGEMENT_SYSTEM',
  EVALUATION_TOOLS: 'EVALUATION_TOOLS',
  HUMAN_RESOURCE_MANAGEMENT: 'HUMAN_RESOURCE_MANAGEMENT',
  BLOG: 'BLOG',
};

export const PAGE_NAMES: PageNames = {
  HOME: 'home',
  TOP_MANAGEMENT: 'topManagement',
  HR: 'hr',
  EMPLOYEES: 'employees',
  TECHNICAL_CAPABILITIES: 'technicalCapabilities',
  LEARNING_MANAGEMENT_SYSTEM: 'learningManagementSystem',
  EVALUATION_TOOLS: 'evaluationTools',
  HUMAN_RESOURCE_MANAGEMENT: 'humanResourceManagement',
  BLOG: 'blog',
  COMPETENCY_MODEL: 'competencyModel',
  PROMO: 'promo',
  TRAFORY_POLICY: 'trafory-policy',
};

export const ROUTES = {
  HOME: '/',
  TOP_MANAGEMENT: '/top-management',
  BUSINESS: '/business',
  HR: '/hr',
  EMPLOYEES: '/employees',
  TECHNICAL_CAPABILITIES: '/technical-capabilities',
  LEARNING_MANAGEMENT_SYSTEM: '/learning-management-system',
  EVALUATION_TOOLS: '/evaluation-tools',
  HUMAN_RESOURCE_MANAGEMENT: '/human-resource-management',
  BLOG: '/blog',
  COMPETENCY_MODEL: '/bonus',
  PROMO: '/promo',
};

// locale keys

export const localeKeys = {
  watchVideo: 'btn.watch-video',
  getAccess: 'btn.get-access',
  contact: 'btn.contact',
  more: 'btn.more',
  mainScreenDescription: 'main-screen.description',
  footerTitle: 'footer.title',
  footerDescription: 'footer.description',
  footerInfoTime: 'footer.info.time',
  footerInfoPhone: 'footer.info.phone',
  footerInfoMail: 'footer.info.mail',
  footerInfoLocation: 'footer.info.location',
  footerInfoLinksCookiesPolicy: 'footer.info.links.cookies-policy',
  footerInfoPersonalDataPolicy: 'footer.info.links.personal-data-policy',
  footerInfoLinksSecurityPolicy: 'footer.info.links.security-policy',
  footerInfoLinksPrivacyPolicy: 'footer.info.links.privacy-policy',
  footerInfoLinksTermsOfUse: 'footer.info.links.terms-of-use',
  footerInfoLinksSupport: 'footer.info.links.support',
  footerInfoLinksContacts: 'footer.info.links.contacts',
  footerInfoLinksCareer: 'footer.info.links.career',
  footerInfoLinksAbout: 'footer.info.links.about',
  unidevCopyright: 'unidev.copyright',
  traforyPurposeTitle: 'trafory-purpose.title',
  traforyPurposeDescription: 'trafory-purpose.description',
  traforyPurposeManagementTitle: 'trafory-purpose.management.title',
  traforyPurposeManagement1: 'trafory-purpose.management.1',
  traforyPurposeManagement2: 'trafory-purpose.management.2',
  traforyPurposeManagement3: 'trafory-purpose.management.3',
  traforyPurposeManagement4: 'trafory-purpose.management.4',
  traforyPurposeHrTitle: 'trafory-purpose.hr.title',
  traforyPurposeHr1: 'trafory-purpose.hr.1',
  traforyPurposeHr2: 'trafory-purpose.hr.2',
  traforyPurposeHr3: 'trafory-purpose.hr.3',
  traforyPurposeHr4: 'trafory-purpose.hr.4',
  traforyPurposeHr5: 'trafory-purpose.hr.5',
  traforyPurposeEmployeeTitle: 'trafory-purpose.employee.title',
  traforyPurposeEmployee1: 'trafory-purpose.employee.1',
  traforyPurposeEmployee2: 'trafory-purpose.employee.2',
  traforyPurposeEmployee3: 'trafory-purpose.employee.3',
  traforyPurposeEmployee4: 'trafory-purpose.employee.4',
  technicalCapabilitiesTitle: 'technical-capabilities.title',
  technicalCapabilitiesSupportHint: 'technical-capabilities.support.hint',
  traforyInstrumentsTitle: 'trafory-instruments.title',
  traforyInstrumentsHumanResourceTitle: 'trafory-instruments.human-resource.title',
  traforyPartnersTitle: 'trafory-partners.title',
  traforyAdvantagesTitle: 'trafory-advantages.title',
  mobileAppTitle: 'mobile-app.title',
  mobileAppDescription: 'mobile-app.description',
  usersGeographyTitle: 'users-geography.title',
  partnersInviteTitle: 'partners-invite.title',
  partnersInviteDescription: 'partners-invite.description',
  partnersInviteLink: 'partners-invite.link',
  traforyPossibilitiesTitle: 'trafory-possibilities.title',
  integrationResultsTitle: 'integration-results.title',
  yourBenefitsTitle: 'your-benefits.title',
  ourTeamTitle: 'our-team.title',
  traforyExperienceTitle: 'trafory-experience.title',
  aboutEvent: 'about-event',
  vacancies: 'vacancies',
  traforyRoadmapTitle: 'trafory-roadmap.title',
  blog: 'blog',
  minutesShort: 'minutes.short',
  allPosts: 'all-posts',
  reviews: 'reviews',
  cookiesPolicy: 'cookies-policy',
  accept: 'accept',
  promoTitle: 'promo.title',
  promoBonusTitle: 'promo.bonus.title',
  hrProcessesAudit: 'hr-processes-audit',
  informationSecurityCourse: 'information-security-course',
  submitYourApplication: 'submit-your-application',
  madeInBelarus: 'made-in-belarus',
  offerConditions: 'offer-conditions',
  employeesCount: 'employees-count',
  oneTimePayment: 'one-time-payment',
  or: 'or',
  paymentBefore: 'payment-before',
  promoFunctionalTitle: 'promo.functional.title',
};

export const mediaBreakpoints = {
  xs: 0,
  sm: 720,
  md: 1025,
  lg: 1440,
};

export const tabNames = {
  hr: 'hr',
  'top-management': 'top-management',
  employees: 'employees',
};

export const FORM_TYPES = {
  DEMO: 'demo',
  SUPPORT: 'support',
  COMPETENCY_MODEL: 'competencyModel',
  PROMO: 'promo',
};

export enum BlogTags {
  'company-cases' = 1,
  'news' = 2,
  'posts' = 3,
  'events' = 4,
}
