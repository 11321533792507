import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { landingLocales } from '../../../constants';

type Props = {
  isDesktop?: boolean,
  isSticky?: boolean
};

export const LanguageSelect = ({ isDesktop, isSticky }: Props) => {
  const { i18n: { changeLanguage, language } } = useTranslation();
  const closeLanguageSelect = useCallback(() => {
  }, []);

  const [isHoverState, setHoverState] = useState<boolean>(false);

  const handleOpen = useCallback((isOpen: boolean) => {
    if (isOpen && !isHoverState) {
      setHoverState(true);
    }
  }, [isHoverState]);

  const onMouseEnter = () => {
    if (isHoverState) {
      setHoverState(false);
    }
  };

  const currentLanguage = Object.values(landingLocales).includes(language)
    ? language : Object.values(landingLocales).filter(
      (str: string) => language.toLowerCase().includes(str),
    )[0]
    || landingLocales.EN;

  const handleLanguageChange = useCallback((value: string) => {
    changeLanguage(value);

    if (!isDesktop) {
      closeLanguageSelect();
    }
  }, [changeLanguage, closeLanguageSelect, isDesktop]);

  useEffect(() => {
    closeLanguageSelect();
  }, [closeLanguageSelect, isSticky]);

  return (
    <Dropdown
      trigger={isDesktop ? ['hover', 'click'] : ['click']}
      menu={{
        items: Object.entries(landingLocales).map(
          ([key, value]) => ({
            key,
            onClick: () => { handleLanguageChange(value); },
            onMouseEnter,
            className: currentLanguage === value && isHoverState ? 'selected-language' : undefined,
            label: (
              <div
                className="line-height-1_2"
                key={key}
              >
                {key}
              </div>
            ),
          }),
        ),
      }}
      onVisibleChange={handleOpen}
      className="landing-header-language-select d-flex align-items-center z-1 me-2_5 me-sm-1 me-md-4_5"
    >
      <div className={classNames('text-uppercase', {
        'text-primary': !isSticky,
        'text-white': isSticky,
      })}
      >
        {currentLanguage}
        <CaretDownOutlined
          className="landing-header-language-select-arrow ms-1"
          style={{ color: '#3396AA', zIndex: -1 }}
        />
      </div>
    </Dropdown>
  );
};

LanguageSelect.defaultProps = {
  isDesktop: false,
  isSticky: false,
};
