import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { IconCheck } from '../icons/icon-check';
import { localeKeys } from '../../constants';
import { AnchorLinkSmooth } from '../anchor-link/anchor-link';
import { Img } from '../img/img';

import './instruments-info-block.scss';

type Props = {
  items: {
    id: number,
    listItems: number[],
  }[],
  page: string
};

export const InstrumentsInfoBlock = ({ items, page }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="instruments-info-block px-sm-3 px-lg-20 pb-2_5 mx-auto">
      <div className="instruments-info-block-items">
        {items.map(({ id, listItems }) => {
          const item = `instruments-info-block.${page}.item${id + 1}`;

          return (
            <div
              id={String(id)}
              key={id}
              className="instruments-info-block-item d-flex justify-content-sm-end mb-8_75 mb-sm-10 mb-lg-15"
            >
              <div className="px-2 px-sm-0 d-flex flex-column">
                <div className="mb-0_5 mb-md-1_75 mb-lg-2_5">
                  <div className="text-medium md-h4 text-primary font-weight-bold d-inline-block mb-1_5 text-uppercase">
                    {t(`${item}.title`)}
                  </div>
                  {listItems.map((i) => (
                    <div className="text-md-main2 d-flex mb-2 mb-sm-2_5 mb-lg-3_75" key={`${id}-${i}`}>
                      <div>
                        <IconCheck />
                      </div>
                      <div className="instruments-info-block-item-description position-relative ms-1">
                        {t(`${item}.description${i + 1}`)}
                      </div>
                    </div>
                  ))}
                </div>

                <AnchorLinkSmooth
                  to="#bitrix-form"
                  className="align-self-center align-self-sm-start"
                >
                  <Button
                    className="px-md-3_75"
                    type="primary"
                    size="large"
                  >
                    {t(localeKeys.getAccess)}
                  </Button>
                </AnchorLinkSmooth>
              </div>

              <div>
                <Img
                  className="instruments-info-block-item-image mt-2_5 mt-md-3"
                  alt={`screen${id + 1}`}
                  src={`images/trafory-instruments/${page}/${id + 1}.webp`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
