import React from 'react';

type Props = {
  className?: string,
};

export const IconHat = ({ className }: Props) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#8243AF" fillOpacity="0.1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.6301 27.1012C26.6301 27.3931 26.3935 27.6297 26.1016 27.6297C25.8097 27.6297 25.5731 27.3931 25.5731 27.1012C25.5731 26.8094 25.8097 26.5728 26.1016 26.5728C26.3935 26.5728 26.6301 26.8094 26.6301 27.1012ZM26.5923 28.5492C27.1958 28.3448 27.6301 27.7737 27.6301 27.1012C27.6301 26.2571 26.9458 25.5728 26.1016 25.5728C25.2575 25.5728 24.5731 26.2571 24.5731 27.1012C24.5731 27.791 25.03 28.374 25.6577 28.5642L24.6321 30.2874C24.3345 30.7873 24.6948 31.421 25.2765 31.421H26.9779C27.559 31.421 27.9194 30.7886 27.6231 30.2886L26.5923 28.5492ZM26.5391 30.421H25.7163L26.1286 29.7282L26.5391 30.421Z" fill="#8243AF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0417 14.827L20.013 10.0721L29.96 14.8154L19.9886 19.5693L10.0417 14.827ZM20.1726 8.78888L31.3854 14.1358L31.4051 14.1452L31.6749 14.2739L32.1322 14.4919C32.4164 14.6274 32.4198 15.0135 32.138 15.1479L31.6846 15.364L31.4171 15.4916L31.3975 15.5009L28.6389 16.8161V22.2846C28.6389 23.1215 28.0843 23.8571 27.2797 24.0873L23.9542 25.0387C21.37 25.778 18.6303 25.778 16.0462 25.0387L12.7207 24.0873C11.916 23.8571 11.3614 23.1215 11.3614 22.2846V16.8155L8.61635 15.5067L8.59662 15.4973L8.32684 15.3687L7.8695 15.1507C7.5853 15.0152 7.58183 14.6291 7.86361 14.4947L8.31705 14.2785L8.58453 14.151L8.60409 14.1416L19.8305 8.78818C19.9377 8.73703 20.0644 8.73729 20.1726 8.78888ZM12.6114 17.4114V22.2846C12.6114 22.5635 12.7963 22.8087 13.0645 22.8855L16.39 23.8369C18.7494 24.5119 21.2509 24.5119 23.6103 23.8369L26.9359 22.8855C27.2041 22.8087 27.3889 22.5635 27.3889 22.2846V17.412L20.1711 20.8532C20.0639 20.9043 19.9372 20.904 19.829 20.8525L12.6114 17.4114Z" fill="#8243AF" />
    <path d="M20.2256 13.6631C19.9786 13.5396 19.6782 13.6398 19.5547 13.8867C19.4312 14.1337 19.5314 14.4341 19.7783 14.5576L20.2256 13.6631ZM26.1158 17.1673H26.6158V16.8583L26.3394 16.7201L26.1158 17.1673ZM19.7783 14.5576L25.8922 17.6145L26.3394 16.7201L20.2256 13.6631L19.7783 14.5576ZM25.6158 17.1673V26.0415H26.6158V17.1673H25.6158Z" fill="#8243AF" />
  </svg>
);

IconHat.defaultProps = {
  className: null,
};
