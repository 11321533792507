import React from 'react';

type Props = {
  className?: string
};

export const IconLinkedin = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5661 1.25H11.6H12.4H12.4339C14.0854 1.25 15.3708 1.24999 16.4019 1.33424C17.4488 1.41977 18.2994 1.59588 19.0644 1.98571C20.3345 2.63285 21.3671 3.66547 22.0143 4.93556C22.4041 5.70065 22.5802 6.55124 22.6658 7.59814C22.75 8.62922 22.75 9.91461 22.75 11.566V11.6V12.4V12.434C22.75 14.0854 22.75 15.3708 22.6658 16.4019C22.5802 17.4488 22.4041 18.2994 22.0143 19.0644C21.3671 20.3345 20.3345 21.3671 19.0644 22.0143C18.2994 22.4041 17.4488 22.5802 16.4019 22.6658C15.3708 22.75 14.0854 22.75 12.434 22.75H12.4H11.6H11.566C9.91461 22.75 8.62922 22.75 7.59814 22.6658C6.55124 22.5802 5.70065 22.4041 4.93556 22.0143C3.66547 21.3671 2.63285 20.3345 1.98571 19.0644C1.59588 18.2994 1.41977 17.4488 1.33424 16.4019C1.24999 15.3708 1.25 14.0854 1.25 12.4339V12.4V11.6V11.5661C1.25 9.91462 1.24999 8.62922 1.33424 7.59814C1.41977 6.55124 1.59588 5.70065 1.98571 4.93556C2.63285 3.66547 3.66547 2.63285 4.93556 1.98571C5.70065 1.59588 6.55124 1.41977 7.59814 1.33424C8.62922 1.24999 9.91462 1.25 11.5661 1.25ZM7.72029 2.82925C6.76615 2.90721 6.13493 3.05809 5.61655 3.32222C4.6287 3.82555 3.82555 4.6287 3.32222 5.61655C3.05809 6.13493 2.90721 6.76615 2.82925 7.72029C2.75058 8.68317 2.75 9.90747 2.75 11.6V12.4C2.75 14.0925 2.75058 15.3168 2.82925 16.2797C2.90721 17.2338 3.05809 17.8651 3.32222 18.3834C3.82555 19.3713 4.6287 20.1744 5.61655 20.6778C6.13493 20.9419 6.76615 21.0928 7.72029 21.1707C8.68317 21.2494 9.90747 21.25 11.6 21.25H12.4C14.0925 21.25 15.3168 21.2494 16.2797 21.1707C17.2338 21.0928 17.8651 20.9419 18.3834 20.6778C19.3713 20.1744 20.1744 19.3713 20.6778 18.3834C20.9419 17.8651 21.0928 17.2338 21.1707 16.2797C21.2494 15.3168 21.25 14.0925 21.25 12.4V11.6C21.25 9.90747 21.2494 8.68317 21.1707 7.72029C21.0928 6.76615 20.9419 6.13493 20.6778 5.61655C20.1744 4.6287 19.3713 3.82555 18.3834 3.32222C17.8651 3.05809 17.2338 2.90721 16.2797 2.82925C15.3168 2.75058 14.0925 2.75 12.4 2.75H11.6C9.90747 2.75 8.68317 2.75058 7.72029 2.82925Z"
      fill="#3396AA"
      className="filled-path"
    />
    <path
      d="M8.17273 8.38737H8.18652C8.92661 8.38737 9.38737 7.85684 9.38737 7.19368C9.37357 6.51594 8.92661 6 8.20058 6C7.47456 6 7 6.51594 7 7.19368C7 7.85684 7.4605 8.38737 8.17273 8.38737ZM7 9.18316H9.38737V16.08H7V9.18316ZM17.6105 11.7032C17.6105 10.3113 16.4824 9.18316 15.0905 9.18316C14.2807 9.18316 13.5621 9.56673 13.1011 10.1604V9.18316H10.7137V16.08H13.1011V12.1011C13.1011 11.5151 13.5761 11.04 14.1621 11.04C14.7481 11.04 15.2232 11.5151 15.2232 12.1011V16.08H17.6105C17.6105 16.08 17.6105 12.0891 17.6105 11.7032Z"
      fill="#3396AA"
      className="filled-path"
    />
  </svg>
);

IconLinkedin.defaultProps = {
  className: null,
};
