import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../constants';

export const CookiesPolicy = () => {
  const [isCookiesAccepted, acceptCookies] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      acceptCookies(window.localStorage.getItem('isCookiesAccepted') === 'true');
    }, 3000);
  }, []);

  const handleClick = () => {
    window.localStorage.setItem('isCookiesAccepted', 'true');
    acceptCookies(true);
  };

  return (
    <div
      className="bg-primary position-fixed w-100 cookies-backdrop-shadow"
      style={{
        bottom: 0,
        left: 0,
        transform: `translateY(${isCookiesAccepted ? 100 : 0}%)`,
        transition: '.3s',
        zIndex: 10151,
      }}
    >
      <div
        className="px-2 pt-2_5 pb-3 d-flex flex-column flex-md-row
        align-items-center inner-container text-center text-small text-white text-md-start text-md-medium"
      >
        {t(localeKeys.cookiesPolicy)}

        <Button
          onClick={handleClick}
          className="text-primary bg-white px-2 py-1_25 d-flex align-items-center mt-2 mt-sm-3 mt-md-0 ms-md-2"
        >
          {t(localeKeys.accept)}
        </Button>
      </div>
    </div>
  );
};
