import React from 'react';
import { LandingHelmet } from '../../components/landing-helmet/landing-helmet';
import { MainScreen } from '../../components/main-screen/main-screen';
import { PAGE_NAMES } from '../../constants';
import { HrInstruments } from '../../components/hr-instruments/hr-instruments';
import { TraforyExperience } from '../../components/trafory-experience/trafory-experience';
import { MobileAppInfo } from '../../components/mobile-app-info/mobile-app-info';

export const Hr = () => (
  <div className="w-100">
    <LandingHelmet page={PAGE_NAMES.HR} />
    <div className="main-container">
      <MainScreen page={PAGE_NAMES.HR} />
      <HrInstruments />
    </div>
    <div className="bg-primary bg-opacity-5">
      <div className="main-container">
        <TraforyExperience />
      </div>
    </div>
    <MobileAppInfo />
  </div>
);
