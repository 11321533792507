import React, { useEffect } from 'react';
import classNames from 'classnames';
import { FORM_TYPES } from '../../constants';
import { AppConstants } from '../../app-constants';

const formTypes = {
  [FORM_TYPES.DEMO]: {
    url: `${AppConstants.bitrixUrl}/crm/form/loader_11.js`,
    data: 'inline/11/gyfb3y',
  },
  [FORM_TYPES.COMPETENCY_MODEL]: {
    url: `${AppConstants.bitrixUrl}/crm/form/loader_59.js`,
    data: 'inline/59/4jfn2m',
  },
  [FORM_TYPES.SUPPORT]: {
    url: `${AppConstants.bitrixUrl}/crm/form/loader_53.js`,
    data: 'inline/53/7tomgv',
  },
  [FORM_TYPES.PROMO]: {
    url: `${AppConstants.bitrixUrl}/crm/form/loader_69.js`,
    data: 'inline/69/t7qgnf',
  },
};

type Props = {
  formType: string,
  className?: string,
};

export const BitrixForm = ({ formType, className }: Props) => {
  useEffect(() => {
    const script: any = document.createElement('script'); script.async = true;
    script.src = `${formTypes[formType].url}?${(Date.now() / 180000) || 0}`;
    const tag = document.getElementsByTagName('script')[0]; tag.parentNode?.insertBefore(script, tag);
  }, [formType]);

  return (
    <div id="bitrix-form" className={classNames(className, `position-relative bitrix-form bitrix-form-${formType}`)}>
      {[FORM_TYPES.DEMO, FORM_TYPES.PROMO].includes(formType) && (
        <div
          className="anchor position-absolute"
          style={{ top: '-10vw' }}
        />
      )}
      <script
        data-b24-form={formTypes[formType].data}
        data-skip-moving="true"
      />
    </div>
  );
};

BitrixForm.defaultProps = {
  className: null,
};
